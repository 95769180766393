import React, { useState } from 'react';
import { FaUpload, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useAuth } from '../security/security/AuthContext';
import './admin.css';

const AddProduct = () => {
  const [images, setImages] = useState([]);
  const { userDetails } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    cycle: '',
    available: '',
    dailyIncome: '',
    totalIncome: '',
    price: '',
    productType: 'NORMAL',
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (images.length + files.length > 4) {
      toast.error('You can only upload up to 4 images.');
      return;
    }
    setImages([...images, ...files]);
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    if (!token) {
      toast.error('Authentication token is missing.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('cycle', formData.cycle);
    formDataToSend.append('available', formData.available);
    formDataToSend.append('dailyIncome', formData.dailyIncome);
    formDataToSend.append('totalIncome', formData.totalIncome);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('productType', formData.productType);
    
    images.forEach((image) => {
      formDataToSend.append('photos', image);
    });

    setLoading(true);

    try {
      const response = await axios.post('https://api.vanguardafriholdings.com/api/products/add', formDataToSend, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Product added successfully!');
        setFormData({
          name: '',
          cycle: '',
          available: '',
          dailyIncome: '',
          totalIncome: '',
          price: '',
        });
        setImages([]);
      }
    } catch (error) {
      toast.error('Failed to add product. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="addproduct-container">
      <h1 className="addproduct-title">Add New Product</h1>
      <form onSubmit={handleSubmit} className="addproduct-form">
        <div className="addproduct-form-group">
          <label htmlFor="name">Product Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
          <label htmlFor="cycle">Cycle (Days)</label>
          <input
            type="number"
            id="cycle"
            name="cycle"
            value={formData.cycle}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
          <label htmlFor="available">Available</label>
          <input
            type="number"
            id="available"
            name="available"
            value={formData.available}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
          <label htmlFor="dailyIncome">Daily Income (KES)</label>
          <input
            type="number"
            id="dailyIncome"
            name="dailyIncome"
            value={formData.dailyIncome}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
          <label htmlFor="totalIncome">Total Income (KES)</label>
          <input
            type="number"
            id="totalIncome"
            name="totalIncome"
            value={formData.totalIncome}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
          <label htmlFor="price">Price (KES)</label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="addproduct-form-group">
        <label htmlFor="productType">Product Type</label>
        <select
          id="productType"
          name="productType"
          value={formData.productType}
          onChange={handleInputChange}
          required
        >
          <option value="NORMAL">Normal</option>
          <option value="HOT">Hot</option>
        </select>
      </div>
        <div className="addproduct-form-group">
          <label>Images (Maximum 4)</label>
          <div className="addproduct-image-upload">
            <label htmlFor="images" className="addproduct-image-upload-label">
              <FaUpload /> Upload Images
            </label>
            <input
              type="file"
              id="images"
              onChange={handleImageUpload}
              multiple
              accept="image/*"
              className="addproduct-image-input"
            />
          </div>
          <div className="addproduct-image-preview">
            {images.map((image, index) => (
              <div key={index} className="addproduct-image-preview-item">
                <img src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
                <button type="button" onClick={() => removeImage(index)} className="addproduct-image-remove">
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
        </div>
        <button type="submit" className="addproduct-submit-btn" disabled={loading}>
          {loading ? 'Adding Product...' : 'Add Product'}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddProduct;