import { createContext, useContext, useState, useEffect } from "react";
import { apiClient } from "../api/ApiClient";
import { executeJwtAuthenticationService, fetchUserDetailsService } from "../api/AuthenticationApiService";
import Loading from "../../userDashboard/auth/loaders/Loading";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [username, setUsername] = useState(null);
    const [token, setToken] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    const updateUserDetails = (newDetails) => {
        setUserDetails(newDetails);
    };

    const login = async (email, password) => {
        try {
            const response = await executeJwtAuthenticationService(email, password);
    
            if (response.status === 200) {
                const jwtToken = 'Bearer ' + response.data.access_token;
    
                setAuthenticated(true);
                setUsername(email);
                setToken(jwtToken);
    
                localStorage.setItem('token', jwtToken);
                localStorage.setItem('email', email);
    
                apiClient.interceptors.request.use((config) => {
                    config.headers.Authorization = jwtToken;
                    return config;
                });

                const userDetailsResponse = await fetchUserDetailsService(email);
                setUserDetails(userDetailsResponse.data);
    
                return { success: true };
            }
        } catch (error) {
            console.error('Login error:', error.response || error);

            if (error.response) {
                switch (error.response.status) { 
                    case 401:
                        return { success: false, message: 'Invalid email or password' };
                    case 403:
                        return { success: false, message: 'Please verify your email to login' };
                    case 400:
                        return { success: false, message: 'An unexpected error occurred, please try again' };
                    case 404:
                        return { success: false, message: 'User not found. Please check your email or sign up.' };
                    default:
                        return { success: false, message: 'An unexpected error occurred' };
                }
            }
            return { success: false, message: 'An unexpected error occurred' };
        }
    };

    const logout = () => {
        setAuthenticated(false);
        setToken(null);
        setUsername(null);
        setUserDetails(null);
        localStorage.removeItem('token');
        localStorage.removeItem('email');
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const storedToken = localStorage.getItem('token');
                const storedUsername = localStorage.getItem('email');

                if (storedToken && storedUsername) {
                    setAuthenticated(true);
                    setUsername(storedUsername);
                    setToken(storedToken);

                    apiClient.interceptors.request.use((config) => {
                        config.headers.Authorization = storedToken;
                        return config;
                    });

                    const response = await fetchUserDetailsService(storedUsername);
                    setUserDetails(response.data);
                }
            } catch (error) {
                console.error('Error initializing auth:', error);
                logout();
            } finally {
                setIsLoading(false);
            }
        };

        initializeAuth();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            login, 
            logout, 
            username, 
            token, 
            userDetails,
            updateUserDetails,
            isLoading
        }}>
            {children}
        </AuthContext.Provider>
    );
}