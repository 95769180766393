import React, { useState, useEffect } from 'react';
import { 
  FaCheckCircle, 
  FaTimesCircle, 
  FaImage, 
  FaEye, 
  FaSpinner 
} from 'react-icons/fa';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuth } from '../security/security/AuthContext';
const AdminProofs = () => {
  const [proofs, setProofs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProof, setSelectedProof] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    fetchProofs();
  }, []);

  const fetchProofs = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.vanguardafriholdings.com/api/admin/proofs', {
        headers: { Authorization: token }
      });
      setProofs(response.data);
    } catch (error) {
      console.error('Error fetching proofs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveProof = async (proofId) => {
    try {
      await axios.put(`https://api.vanguardafriholdings.com/api/admin/${proofId}/approve`, null, {
        headers: { Authorization: token }
      });
      // Refresh proofs list after approval
      fetchProofs();
    } catch (error) {
      console.error('Error approving proof:', error);
    }
  };

  const handleRejectProof = async (proofId) => {
    try {
      await axios.put(`https://api.vanguardafriholdings.com/api/admin/${proofId}/reject`, null, {
        headers: { Authorization: token }
      });
      // Refresh proofs list after rejection
      fetchProofs();
    } catch (error) {
      console.error('Error rejecting proof:', error);
    }
  };

  const ImageModal = ({ proof, onClose }) => (
    <div className="adminpage-proof-modal-overlay">
      <div className="adminpage-proof-modal">
        <button className="adminpage-proof-modal-close" onClick={onClose}>×</button>
        <div className="adminpage-proof-modal-content">
          <img 
            src={`https://api.vanguardafriholdings.com${proof.imageUrl}`} 
            alt="Proof" 
            className="adminpage-proof-modal-image" 
          />
          <div className="adminpage-proof-modal-details">
            <h3>Proof Details</h3>
            <p><strong>Description:</strong> {proof.description}</p>
            <p><strong>Phone Number:</strong> {proof.phoneNumber}</p>
            <p><strong>Status:</strong> {proof.status}</p>
            <p><strong>Submitted:</strong> {format(new Date(proof.createdAt), 'PPp')}</p>
            <div className="adminpage-proof-modal-actions">
              <button 
                className="adminpage-btn-approve"
                onClick={() => handleApproveProof(proof.id)}
                disabled={proof.status === 'APPROVED'}
              >
                <FaCheckCircle /> Approve
              </button>
              <button 
                className="adminpage-btn-reject"
                onClick={() => handleRejectProof(proof.id)}
                disabled={proof.status === 'REJECTED'}
              >
                <FaTimesCircle /> Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="adminpage-proofs">
      <div className="adminpage-card">
        <div className="adminpage-card-header">
          <h5>Transaction Proofs Management</h5>
        </div>
        <div className="adminpage-card-body">
          {loading ? (
            <div className="adminpage-loading">
              <FaSpinner className="adminpage-spinner" />
              <p>Loading proofs...</p>
            </div>
          ) : (
            <table className="adminpage-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Description</th>
                  <th>Phone Number</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {proofs.map((proof) => (
                  <tr key={proof.id}>
                    <td>{proof.userEmail}</td>
                    <td>
                      <div className="adminpage-description-cell">
                        {proof.description}
                      </div>
                    </td>
                    <td>{proof.phoneNumber}</td>
                    <td>
                      <span className={`adminpage-status adminpage-status-${proof.status.toLowerCase()}`}>
                        {proof.status}
                      </span>
                    </td>
                    <td>{format(new Date(proof.createdAt), 'PP')}</td>
                    <td>
                      <div className="adminpage-proof-actions">
                        <button 
                          className="adminpage-btn-view"
                          onClick={() => {
                            setSelectedProof(proof);
                            setShowImageModal(true);
                          }}
                        >
                          <FaEye />
                        </button>
                        <button 
                          className="adminpage-btn-approve"
                          onClick={() => handleApproveProof(proof.id)}
                          disabled={proof.status === 'APPROVED'}
                        >
                          <FaCheckCircle />
                        </button>
                        <button 
                          className="adminpage-btn-reject"
                          onClick={() => handleRejectProof(proof.id)}
                          disabled={proof.status === 'REJECTED'}
                        >
                          <FaTimesCircle />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      
      {showImageModal && selectedProof && (
        <ImageModal 
          proof={selectedProof} 
          onClose={() => {
            setShowImageModal(false);
            setSelectedProof(null);
          }} 
        />
      )}
    </div>
  );
};

export default AdminProofs;