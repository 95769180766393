import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import './style.css';
import axios from 'axios';

const CashoutView = ({ setCurrentView, userDetails, token, calculateTotalBalance, setToastNotification }) => {
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchWithdrawalHistory();
  }, []);

  const fetchWithdrawalHistory = async () => {
    try {
      const response = await axios.get(
        `https://api.vanguardafriholdings.com/api/withdrawals/history/${userDetails.email}`,
        {
          headers: { 'Authorization': token }
        }
      );
      setWithdrawalHistory(response.data);
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
      setToastNotification({ 
        message: 'Failed to fetch withdrawal history', 
        type: 'error' 
      });
    }
  };

  const handleWithdrawal = async () => {
    setIsProcessing(true);
    setErrorMessage('');

    try {
      const response = await axios.post(
        'https://api.vanguardafriholdings.com/api/withdrawals/request',
        {
          email: userDetails.email,
          amount: withdrawAmount
        },
        {
          headers: { 'Authorization': token }
        }
      );

      setToastNotification({ 
        message: 'Withdrawal request submitted successfully', 
        type: 'success' 
      });
      setWithdrawAmount('');
      fetchWithdrawalHistory();
      
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Failed to process withdrawal request';
      setErrorMessage(errorMessage);
      setToastNotification({ message: errorMessage, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="withdrawal-cashout-container">
      <div className="withdrawal-cashout-header">
        <button className="withdrawal-cashout-back" onClick={() => setCurrentView('main')}>
          <ArrowLeft className="w-5 h-5" /> Back
        </button>
        <h2>My Wallet</h2>
      </div>

      <div className="withdrawal-cashout-main">
        <div className="withdrawal-cashout-balance">
          <div className="withdrawal-cashout-balance-card">
            <span className="withdrawal-cashout-balance-label">Total Balance</span>
            <h1 className="withdrawal-cashout-balance-amount">
              KSH {calculateTotalBalance().toLocaleString()}
            </h1>
            <div className="withdrawal-cashout-balance-decoration"></div>
          </div>
        </div>

        <div className="withdrawal-cashout-form">
          <h3>Request Withdrawal</h3>
          {errorMessage && (
            <div className="withdrawal-cashout-error">{errorMessage}</div>
          )}
          <div className="withdrawal-cashout-input-group">
            <input 
              type="number" 
              placeholder="Amount (Min: KES 100)" 
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              className="withdrawal-cashout-input" 
            />
            <button 
              className="withdrawal-cashout-submit"
              onClick={handleWithdrawal}
              disabled={isProcessing || !withdrawAmount || Number(withdrawAmount) < 100}
            >
              {isProcessing ? (
                <span className="withdrawal-cashout-loading">Processing...</span>
              ) : (
                'Withdraw Funds'
              )}
            </button>
          </div>
        </div>

        <div className="withdrawal-cashout-history">
          <h3>Recent Withdrawals</h3>
          <div className="withdrawal-cashout-history-list">
            {withdrawalHistory.map((request, index) => (
              <div key={index} className="withdrawal-cashout-history-item">
                <div className="withdrawal-cashout-history-header">
                  <span className={`withdrawal-cashout-status status-${request.withdrawalStatus.toLowerCase()}`}>
                    {request.withdrawalStatus}
                  </span>
                  <span className="withdrawal-cashout-date">
                    {new Date(request.requestTime).toLocaleDateString()}
                  </span>
                </div>
                <div className="withdrawal-cashout-history-body">
                  <div className="withdrawal-cashout-history-amount">
                    <span className="withdrawal-cashout-label">Requested</span>
                    <span className="withdrawal-cashout-value">
                      KES {request.requestedAmount.toLocaleString()}
                    </span>
                  </div>
                  <div className="withdrawal-cashout-history-receive">
                    <span className="withdrawal-cashout-label">To Receive</span>
                    <span className="withdrawal-cashout-value">
                      KES {request.amountToReceive.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="withdrawal-cashout-history-footer">
                  <span className="withdrawal-cashout-order">
                    Order: #{request.orderNumber}
                  </span>
                  <span className="withdrawal-cashout-time">
                    {new Date(request.requestTime).toLocaleTimeString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashoutView;