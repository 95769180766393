import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaCamera, FaTimes, FaReceipt, FaSpinner } from 'react-icons/fa';
import BottomNavigation from './BottomNavigation';
import { useAuth } from '../security/security/AuthContext';
import axios from 'axios';
import './style.css';
import HomeToastNotification from './auth/loaders/HomeToastNotification';

const Proof = () => {
  const [currentView, setCurrentView] = useState('main');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [proofs, setProofs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails, token, updateUserDetails } = useAuth();
  const [toastNotification, setToastNotification] = useState(null);

  const refreshUserData = async () => {
    try {
      setLoading(true);
      const userResponse = await axios.get(`https://api.vanguardafriholdings.com/api/v1/auth/${userDetails.email}`, {
        headers: {
          'Authorization': token
        }
      });
      updateUserDetails(userResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing user data:', error);
      setToastNotification({ message: 'Failed to refresh user data', type: 'error' });
      setLoading(false);
    }
  };

  const fetchProofs = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.vanguardafriholdings.com/api/proofs/approved');
      setProofs(response.data);
      setLoading(false);  // Fixed: Set loading to false after successful fetch
    } catch (error) {
      console.error('Error fetching proofs:', error);
      setToastNotification({
        message: 'Failed to fetch proofs. Please try again.',
        type: 'error'
      });
      setLoading(false);  // Fixed: Set loading to false on error
    }
  };

  useEffect(() => {
    // Refresh user data when component mounts
    refreshUserData();
    fetchProofs();
  }, []);

  useEffect(() => {
    if (currentView === 'main') {
      refreshUserData();
    }
  }, [currentView]);

  // Rest of the component's functions remain unchanged...
  const handleImageSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      setToastNotification({
        message: 'Please select an image',
        type: 'error'
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('description', description);
    formData.append('phoneNumber', phoneNumber);
    formData.append('userEmail', userDetails.email);

    try {
      await axios.post('https://api.vanguardafriholdings.com/api/proofs/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setToastNotification({
        message: 'Proof submitted successfully!',
        type: 'success'
      });
      setCurrentView('main');
      setDescription('');
      setPhoneNumber('');
      setSelectedImage(null);
    } catch (error) {
      console.error('Error submitting proof:', error);
      setToastNotification({
        message: 'Failed to submit proof. Please try again.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const ProofItem = ({ proof }) => (
    <div className="proofspage__item">
      <div className="proofspage__item-image-container">
        <img 
          src={`https://api.vanguardafriholdings.com${proof.imageUrl}`} 
          alt="Proof" 
          className="proofspage__item-image" 
        />
      </div>
      <div className="proofspage__item-details">
        <p className="proofspage__item-description">{proof.description}</p>
        <p className="proofspage__item-phone">
          {proof.phoneNumber.replace(/(\d{3})\d{4}(\d{3})/, '$1****$2')}
        </p>
      </div>
    </div>
  );

  const EmptyState = () => (
    <div className="proofspage__empty">
      <FaReceipt className="proofspage__empty-icon" />
      <h3 className="proofspage__empty-title">No Proofs Yet</h3>
      <p className="proofspage__empty-text">
        Be the first to share your transaction proof and earn rewards!
      </p>
      <button 
        className="proofspage__empty-button"
        onClick={() => setCurrentView('share')}
      >
        Share Your Proof
      </button>
    </div>
  );

  const renderMainView = () => (
    <div className="proofspage__container">
      {loading ? (
        <div className="adminpage-loading">
          <FaSpinner className="adminpage-spinner" />
          <p>Loading proofs...</p>
        </div>
      ) : (
        <>
          <div className="proofspage__header">
            <h1 className="proofspage__title">Transaction Proofs</h1>
            <p className="proofspage__subtitle">Transaction comments from our clients</p>
            <div className="proofspage__reward-banner">
              <p>Share your withdrawal proof and earn between</p>
              <h3>Ksh 100 - Ksh 2,000</h3>
            </div>
          </div>
          
          {proofs.length > 0 ? (
            <>
              <div className="proofspage__list">
                {proofs.map(proof => (
                  <ProofItem key={proof.id} proof={proof} />
                ))}
              </div>
              <button 
                className="proofspage__add-button" 
                onClick={() => setCurrentView('share')}
              >
                Share Proof
              </button>
            </>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </div>
  );

  const renderShareProofView = () => (
    <div className="proofspage__container">
      <div className="proofspage__share-header">
        <button 
          className="proofspage__back-button" 
          onClick={() => setCurrentView('main')}
        >
          <FaArrowLeft /> Back
        </button>
        <h2 className="proofspage__share-title">Upload Proof</h2>
      </div>
      
      <form onSubmit={handleSubmit} className="proofspage__form">
        <div className="proofspage__form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Share your experience..."
            rows="3"
            required
          />
        </div>
        
        <div className="proofspage__form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
            required
          />
        </div>
        
        <div className="proofspage__upload-area">
          <input
            type="file"
            id="proofImage"
            accept="image/*"
            onChange={handleImageSelect}
            style={{ display: 'none' }}
            required
          />
          <label htmlFor="proofImage" className="proofspage__upload-label">
            {selectedImage ? (
              <div className="proofspage__preview">
                <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
                <button 
                  type="button" 
                  className="proofspage__remove-image" 
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedImage(null);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ) : (
              <div className="proofspage__upload-placeholder">
                <FaCamera className="proofspage__camera-icon" />
                <p>Tap to upload screenshot</p>
              </div>
            )}
          </label>
        </div>
        
        <button 
          type="submit" 
          className="proofspage__submit-button" 
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit Proof'}
        </button>
      </form>
    </div>
  );

  return (
    <div className="proofspage">
      {currentView === 'main' ? renderMainView() : renderShareProofView()}
      <BottomNavigation />
      {toastNotification && (
        <HomeToastNotification
          message={toastNotification.message}
          type={toastNotification.type}
          onClose={() => setToastNotification(null)}
        />
      )}
    </div>
  );
};

export default Proof;