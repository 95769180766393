import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGift, FaApple, FaTimes, FaCheckCircle, FaClock } from 'react-icons/fa';
import axios from 'axios';
import HomeToastNotification from './auth/loaders/HomeToastNotification';
import './style.css';


const appleGadgets = [
  {
    id: 1,
    name: "iPhone 15 Pro Max",
    image: "/apple_iphone.jpg",
    description: "Latest flagship iPhone with stunning camera system",
    shares: 1,
    price: 15000
  },
  {
    id: 2,
    name: "MacBook Air M2",
    image: "/apple_macbook.png",
    description: "Ultra-light laptop with incredible performance",
    shares: 1,
    price: 18000
  },
  {
    id: 3,
    name: "iPad Pro 12.9",
    image: "/apple_ipad.jpg",
    description: "Most powerful iPad with Liquid Retina XDR display",
    shares: 1,
    price: 12000
  },
  {
    id: 4,
    name: "Apple Watch Series 9",
    image: "/apple_watch.jpg",
    description: "Advanced health monitoring and fitness tracking",
    shares: 1,
    price: 8000
  },
  {
    id: 5,
    name: "AirPods Pro 2",
    image: "/apple_airpods.jpg",
    description: "Premium wireless earbuds with active noise cancellation",
    shares: 1,
    price: 2000
  },
  // Add more gadgets...
];

const TaskView = ({ userEmail, onInvest }) => {
  const [openedBoxes, setOpenedBoxes] = useState([]);
  const [selectedGadget, setSelectedGadget] = useState(null);
  const [showPrize, setShowPrize] = useState(false);
  const [pendingGadgets, setPendingGadgets] = useState([]);
  const [lastOpenTime, setLastOpenTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [toastNotification, setToastNotification] = useState(null);

  useEffect(() => {
    fetchOpenedBoxes();
    const timer = setInterval(updateTimeRemaining, 1000);
    return () => clearInterval(timer);
  }, []);

  const fetchOpenedBoxes = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://api.vanguardafriholdings.com/api/tasks/${userEmail}/opened-boxes`);
      setPendingGadgets(response.data.pendingGadgets);
      setLastOpenTime(response.data.lastOpenTime);
      updateTimeRemaining();
    } catch (error) {
      console.error('Error fetching opened boxes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTimeRemaining = () => {
    if (lastOpenTime) {
      const elapsed = Date.now() - new Date(lastOpenTime).getTime();
      const remaining = Math.max(0, 24 * 60 * 60 * 1000 - elapsed);
      setTimeRemaining(remaining);
    } else {
      setTimeRemaining(0);
    }
  };

  const handleBoxOpen = async (index) => {
    if (timeRemaining > 0 || isLoading) return;

    try {
      setIsLoading(true);
      const randomGadget = appleGadgets[Math.floor(Math.random() * appleGadgets.length)];
      
      await axios.post(`https://api.vanguardafriholdings.com/api/tasks/${userEmail}/open-box`, {
        gadgetId: randomGadget.id,
        requiredShares: randomGadget.shares
      });

      setSelectedGadget(randomGadget);
      setShowPrize(true);
      setOpenedBoxes([...openedBoxes, index]);
      await fetchOpenedBoxes();
    } catch (error) {
      console.error('Error opening box:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleInvest = (gadget) => {
  //   setShowPrize(false);
  //   onInvest({
  //     ...gadget,
  //     id: gadget.id,
  //     price: gadget.price / gadget.shares // Price per share
  //   });
  // };

  const handleInvest = () => {
    setToastNotification({ message: 'Ooops,Sorry we are out of stock, Please try again Tomorrow for more Goodies😊...!', type: 'error' });
    
  };

  const formatTime = (ms) => {
    if (ms <= 0) return "Ready!";
    const hours = Math.floor(ms / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="task-container">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="task-header"
      >
        <h2><FaApple className="apple-icon" /> Apple Gadget Rewards</h2>
        <div className={`cooldown-timer ${timeRemaining > 0 ? 'active' : ''}`}>
          <FaClock /> {timeRemaining > 0 ? 'Next box available in:' : 'Ready to open!'} 
          <span>{formatTime(timeRemaining)}</span>
        </div>
      </motion.div>

      <div className="gift-boxes-container">
        <div className="gift-box-grid">
          {[...Array(9)].map((_, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`gift-box ${openedBoxes.includes(index) ? 'opened' : ''} 
                         ${timeRemaining > 0 || isLoading ? 'disabled' : ''}`}
              onClick={() => handleBoxOpen(index)}
            >
              <FaGift className="gift-icon" />
              <div className="gift-box-shine"></div>
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {showPrize && selectedGadget && (
          <motion.div 
            className="prize-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="prize-dialog"
              initial={{ scale: 0.5, y: 100 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.5, y: 100 }}
            >
              <button className="close-btn" onClick={() => setShowPrize(false)}>
                <FaTimes />
              </button>
              
              <motion.div 
                className="prize-content"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <div className="prize-header">
                  <FaCheckCircle className="success-icon" />
                  <h3>Congratulations!</h3>
                </div>
                
                <img 
                  src={selectedGadget.image} 
                  alt={selectedGadget.name} 
                  className="prize-image"
                />
                
                <h4>{selectedGadget.name}</h4>
                <p>{selectedGadget.description}</p>
                
                <div className="shares-info">
                  <p>Required shares to claim: {selectedGadget.shares}</p>
                  <p className="share-price">
                    Price per share: KES {(selectedGadget.price / selectedGadget.shares).toLocaleString()}
                  </p>
                  <button 
                    className="invest-btn" onClick={handleInvest}
                  >
                    Invest Now to Claim
                  </button>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {pendingGadgets.length > 0 && (
        <motion.div 
          className="pending-gadgets"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h3>Your Rewards</h3>
          <div className="pending-gadgets-grid">
            {pendingGadgets.map((gadget) => (
              <motion.div 
                key={gadget.id} 
                className="pending-gadget-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <img src={gadget.image} alt={gadget.name} />
                <div className="pending-gadget-info">
                  <h4>{gadget.name}</h4>
                  <p className="status">
                    Status: <span className={gadget.invested ? 'processing' : 'pending'}>
                      {gadget.invested ? 'Processing' : 'Pending Investment'}
                    </span>
                  </p>
                  {!gadget.invested && (
                    <>
                      <p className="shares-needed">
                        Shares needed: {gadget.requiredShares}
                      </p>
                      <button 
                        className="invest-btn" onClick={handleInvest}
                      >
                        Invest to Claim
                      </button>
                    </>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}

{toastNotification && (
        <HomeToastNotification
          message={toastNotification.message}
          type={toastNotification.type}
          onClose={() => setToastNotification(null)}
        />
      )}
    </div>
  );
};

export default TaskView;