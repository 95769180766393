import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaUsers, FaUserPlus, FaMoneyBillWave, FaChartLine,
  FaSearch, FaEdit, FaCreditCard, FaHistory,
  FaTachometerAlt, FaCheckCircle, FaTimesCircle,
  FaChartBar, FaUserShield, FaFileContract,
  FaCog, FaBell, FaSignOutAlt, FaGlobe,
  FaLightbulb, FaRobot, FaHandHoldingUsd,
  FaProjectDiagram, FaRegNewspaper, FaReceipt,
  FaUser, FaPlus, FaTrash, FaEye, FaBars, FaTimes
} from 'react-icons/fa';
import AdminProofs from './AdminProofs';
import AddProduct from './AddProduct';
import DailyRewards from './DailyRewards';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../security/security/AuthContext';

const Admin = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [selectedUser, setSelectedUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedBalance, setEditedBalance] = useState('');
  const [notifications, setNotifications] = useState(3);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const { userDetails, token, updateUserDetails } = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const authContext = useAuth();
  
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992);


  const [dashboardStats, setDashboardStats] = useState({
    totalUsers: 0,
    todaySignups: 0,
    todayTransactions: 0,
    totalRevenue: 0
  });
  const [recentTransactions, setRecentTransactions] = useState([]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992);
      if (window.innerWidth > 992) {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const handleMenuClick = (tab) => {
    setActiveTab(tab);
    if (isMobileView) {
      setIsSidebarOpen(false);
    }
  };


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const [statsResponse, transactionsResponse] = await Promise.all([
          axios.get('https://api.vanguardafriholdings.com/api/admin/dashboard/stats', {
            headers: { Authorization: token }
          }),
          axios.get('https://api.vanguardafriholdings.com/api/admin/transactions/recent', {
            headers: { Authorization: token }
          })
        ]);

        setDashboardStats(statsResponse.data);
        setRecentTransactions(transactionsResponse.data);
      } catch (err) {
        setError('Failed to load dashboard data');
        console.error('Error fetching dashboard data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);
  

  function logout() {
    authContext.logout();
    navigate('/admin/login');
  }

  useEffect(() => {
    fetchDeposits();
    fetchWithdrawals();
  }, [currentPage]);

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://api.vanguardafriholdings.com/api/admin/users', {
        headers: { Authorization: token }
      });
  
      // Sort the users by ID in descending order
      const sortedUsers = response.data.sort((a, b) => b.id - a.id);
  
      setUsers(sortedUsers);
      setFilteredUsers(sortedUsers);
    } catch (error) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (!searchTerm.trim()) {
      setFilteredUsers(users);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = users.filter(user => 
      user.fullName.toLowerCase().includes(searchTermLower) ||
      user.email.toLowerCase().includes(searchTermLower) ||
      user.mobile.includes(searchTerm)
    );
    setFilteredUsers(filtered);
  };

  const handleUpdateUser = async (userId, updatedData) => {
    try {
      setIsLoading(true);
      const response = await axios.put(
        `https://api.vanguardafriholdings.com/api/admin/users/${userId}`,
        updatedData,
        { headers: { Authorization: token } }
      );
      
      // Update the local state
      const updatedUsers = users.map(user => 
        user.id === userId ? { ...user, ...response.data } : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      setEditMode(false);
      setSelectedUser(null);
      
      // Show success message
      alert('User updated successfully');
    } catch (error) {
      setError('Failed to update user');
      console.error('Error updating user:', error);
    } finally {
      setIsLoading(false);
    }
  };


  // Updated fetchDeposits function with error handling and data validation
  const fetchDeposits = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.vanguardafriholdings.com/api/admin/deposits?page=${currentPage}&size=${itemsPerPage}`, {
        headers: { Authorization: token }
      });
      
      // Validate and transform the data
      const validatedDeposits = response.data.map(deposit => ({
        ...deposit,
        amount: deposit.amount || 0,
        transactionDate: deposit.transactionDate || new Date().toISOString(),
        status: deposit.status || 'PENDING',
        mpesaReceiptNumber: deposit.mpesaReceiptNumber || 'N/A',
        phoneNumber: deposit.phoneNumber || 'N/A'
      }));
      
      setDeposits(validatedDeposits);
    } catch (error) {
      console.error('Error fetching deposits:', error);
      setError('Failed to fetch deposits');
      setDeposits([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  // Updated fetchWithdrawals function with error handling and data validation
  const fetchWithdrawals = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.vanguardafriholdings.com/api/admin/withdrawals?page=${currentPage}&size=${itemsPerPage}`, {
        headers: { Authorization: token }
      });
      
      // Validate and transform the data
      const validatedWithdrawals = response.data.map(withdrawal => ({
        ...withdrawal,
        requestedAmount: withdrawal.requestedAmount || 0,
        amountToReceive: withdrawal.amountToReceive || 0,
        requestTime: withdrawal.requestTime || new Date().toISOString(),
        withdrawalStatus: withdrawal.withdrawalStatus || 'PENDING',
        orderNumber: withdrawal.orderNumber || 'N/A',
        email: withdrawal.email || 'N/A',
        mobile: withdrawal.mobile || 'N/A'
      }));
      
      setWithdrawals(validatedWithdrawals);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setError('Failed to fetch withdrawals');
      setWithdrawals([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  
  const handleDeleteTransaction = async (id) => {
    if (window.confirm('Are you sure you want to delete this transaction?')) {
      try {
        await axios.delete(`https://api.vanguardafriholdings.com/api/admin/transactions/${id}`, {
          headers: { Authorization: token }
        });
        fetchDeposits(); // Refresh the list
      } catch (error) {
        console.error('Error deleting transaction:', error);
      }
    }
  };
  
  const handleApproveWithdrawal = async (id) => {
    try {
      await axios.put(`https://api.vanguardafriholdings.com/api/admin/withdrawals/${id}/approve`, null, {
        headers: { Authorization: token }
      });
      fetchWithdrawals(); // Refresh the list
    } catch (error) {
      console.error('Error approving withdrawal:', error);
    }
  };
  
  const handleDeclineWithdrawal = async (id) => {
    if (window.confirm('Are you sure you want to decline this withdrawal? The amount will be refunded to the user.')) {
      try {
        await axios.put(`https://api.vanguardafriholdings.com/api/admin/withdrawals/${id}/decline`, null, {
          headers: { Authorization: token }
        });
        fetchWithdrawals(); // Refresh the list
      } catch (error) {
        console.error('Error declining withdrawal:', error);
      }
    }
  };


  // Mock data
  useEffect(() => {
    setUsers([
      { id: 1, name: 'John Doe', email: 'john@example.com', wallet: 5000, joinDate: '2024-10-29', status: 'active', investmentPlan: 'Premium', totalInvestments: 25000 },
      { id: 2, name: 'Jane Smith', email: 'jane@example.com', wallet: 3000, joinDate: '2024-10-30', status: 'active', investmentPlan: 'Standard', totalInvestments: 15000 }
    ]);

    setDeposits([
      { id: 1, userId: 1, amount: 1000, date: '2024-10-30', status: 'completed', method: 'Bank Transfer', reference: 'DEP001' },
      { id: 2, userId: 2, amount: 2000, date: '2024-10-30', status: 'pending', method: 'Credit Card', reference: 'DEP002' }
    ]);

    setWithdrawals([
      { id: 1, userId: 1, amount: 500, date: '2024-10-30', status: 'pending', method: 'Bank Transfer', reference: 'WIT001' },
      { id: 2, userId: 2, amount: 1000, date: '2024-10-30', status: 'completed', method: 'Wire Transfer', reference: 'WIT002' }
    ]);
  }, []);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditedBalance(user.wallet.toString());
    setEditMode(true);
  };

  const handleSaveEdit = () => {
    console.log('Saved edit for user:', selectedUser.id, 'new balance:', editedBalance);
    setEditMode(false);
    setSelectedUser(null);
  };
  

  const renderDashboard = () => (
    <>
       <div className="adminpage-dashboard">
      <div className="adminpage-stats-grid">
        <div className="adminpage-stat-card adminpage-gradient-green">
          <div className="adminpage-stat-content">
            <div className="adminpage-stat-info">
              <h6>Total Users</h6>
              <h2>{dashboardStats.totalUsers.toLocaleString()}</h2>
            </div>
            <div className="adminpage-stat-icon">
              <FaUsers size={30} />
            </div>
          </div>
        </div>

        <div className="adminpage-stat-card adminpage-gradient-blue">
          <div className="adminpage-stat-content">
            <div className="adminpage-stat-info">
              <h6>Today's Signups</h6>
              <h2>{dashboardStats.todaySignups}</h2>
            </div>
            <div className="adminpage-stat-icon">
              <FaUserPlus size={30} />
            </div>
          </div>
        </div>

        <div className="adminpage-stat-card adminpage-gradient-purple">
          <div className="adminpage-stat-content">
            <div className="adminpage-stat-info">
              <h6>Today's Transactions</h6>
              <h2>KSH {dashboardStats.todayTransactions.toLocaleString()}</h2>
            </div>
            <div className="adminpage-stat-icon">
              <FaHandHoldingUsd size={30} />
            </div>
          </div>
        </div>

        <div className="adminpage-stat-card adminpage-gradient-orange">
          <div className="adminpage-stat-content">
            <div className="adminpage-stat-info">
              <h6>Total Revenue</h6>
              <h2>KSH {dashboardStats.totalRevenue.toLocaleString()}</h2>
            </div>
            <div className="adminpage-stat-icon">
              <FaChartLine size={30} />
            </div>
          </div>
        </div>
      </div>

      <div className="adminpage-content-grid">
        <div className="adminpage-card adminpage-transactions">
          <div className="adminpage-card-header">
            <h5>Recent Transactions</h5>
          </div>
          <div className="adminpage-card-body">
            <table className="adminpage-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Receipt Number</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {recentTransactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>
                      <div className="adminpage-user-info">
                        <div className="adminpage-user-avatar">
                          <FaUser />
                        </div>
                        <div className="adminpage-user-details">
                          <div className="adminpage-user-reference">{transaction.phoneNumber}</div>
                        </div>
                      </div>
                    </td>
                    <td>KSH {transaction.amount.toLocaleString()}</td>
                    <td>
                      <span className={`adminpage-status adminpage-status-${transaction.status.toLowerCase()}`}>
                        {transaction.status}
                      </span>
                    </td>
                    <td>{transaction.mpesaReceiptNumber}</td>
                    <td>{format(new Date(transaction.transactionDate), 'PPp')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Investment Distribution */}
        <div className="adminpage-card adminpage-investments">
          <div className="adminpage-card-header">
            <h5>Investment Distribution</h5>
          </div>
          <div className="adminpage-card-body">
            <div className="adminpage-investment-item">
              <div className="adminpage-investment-header">
                <span>Apple Stock</span>
                <span className="adminpage-percentage">45%</span>
              </div>
              <div className="adminpage-progress-bar">
                <div className="adminpage-progress adminpage-progress-primary" style={{ width: '45%' }}></div>
              </div>
            </div>

            <div className="adminpage-investment-item">
              <div className="adminpage-investment-header">
                <span>Tech Startups</span>
                <span className="adminpage-percentage">30%</span>
              </div>
              <div className="adminpage-progress-bar">
                <div className="adminpage-progress adminpage-progress-success" style={{ width: '30%' }}></div>
              </div>
            </div>

            <div className="adminpage-investment-item">
              <div className="adminpage-investment-header">
                <span>R&D Projects</span>
                <span className="adminpage-percentage">25%</span>
              </div>
              <div className="adminpage-progress-bar">
                <div className="adminpage-progress adminpage-progress-info" style={{ width: '25%' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </>
  );

  const renderDeposits = () => (
    <div className="adminpage-card">
      <div className="adminpage-card-header">
        <div className="adminpage-header-content">
          <h5 className="adminpage-title">Recent Deposits</h5>
          <div className="adminpage-button-group">
            <button className="adminpage-button adminpage-button-outline">
              <FaFileContract className="adminpage-icon" /> Export Report
            </button>
            <button className="adminpage-button adminpage-button-primary">
              <FaPlus className="adminpage-icon" /> New Deposit
            </button>
          </div>
        </div>
      </div>
      <div className="adminpage-card-body">
        {loading ? (
          <div className="adminpage-loading">Loading...</div>
        ) : error ? (
          <div className="adminpage-error">{error}</div>
        ) : (
          <div className="adminpage-table-container">
            <table className="adminpage-table">
               <thead>
              <tr>
                <th>Receipt Number</th>
                <th>Phone Number</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
              <tbody>
                {deposits.map(deposit => (
                  <tr key={deposit.id}>
                    <td>{deposit.mpesaReceiptNumber}</td>
                    <td>{deposit.phoneNumber}</td>
                    <td>KSH {deposit.amount.toLocaleString()}</td>
                    <td>
                      {/* Safely format the date */}
                      {format(parseISO(deposit.transactionDate), 'PPp')}
                    </td>
                    <td>
                      <span className={`adminpage-status adminpage-status-${deposit.status.toLowerCase()}`}>
                        {deposit.status}
                      </span>
                    </td>
                    <td>
                      <div className="adminpage-actions">
                        <button 
                          className="adminpage-action-button adminpage-delete"
                          onClick={() => handleDeleteTransaction(deposit.id)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          <div className="adminpage-pagination">
            <button 
              onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
              disabled={currentPage === 0}
            >
              Previous
            </button>
            <span>Page {currentPage + 1}</span>
            <button 
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={deposits.length < itemsPerPage}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
  );

  const renderWithdrawals = () => (
    <div className="adminpage-card">
      <div className="adminpage-card-header">
        <div className="adminpage-header-content">
          <h5 className="adminpage-title">Pending Withdrawals</h5>
          <div className="adminpage-button-group">
            <button className="adminpage-button adminpage-button-outline">
              <FaFileContract className="adminpage-icon" /> Export Report
            </button>
          </div>
        </div>
      </div>
      <div className="adminpage-card-body">
      {loading ? (
        <div className="adminpage-loading">Loading...</div>
      ) : (
        <div className="adminpage-table-container">
          <table className="adminpage-table">
            <thead>
              <tr>
                <th>Order Number</th>
                <th>User</th>
                <th>Amount</th>
                <th>Amount to Receive</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
                {withdrawals.map(withdrawal => (
                  <tr key={withdrawal.id}>
                    <td>{withdrawal.orderNumber}</td>
                    <td>
                      <div className="adminpage-user-info">
                        <div className="adminpage-user-details">
                          <div>{withdrawal.email}</div>
                          <div className="adminpage-text-muted">{withdrawal.mobile}</div>
                        </div>
                      </div>
                    </td>
                    <td>KSH {withdrawal.requestedAmount.toLocaleString()}</td>
                    <td>KSH {withdrawal.amountToReceive.toLocaleString()}</td>
                    <td>
                      {/* Safely format the date */}
                      {format(parseISO(withdrawal.requestTime), 'PPp')}
                    </td>
                    <td>
                      <span className={`adminpage-status adminpage-status-${withdrawal.withdrawalStatus.toLowerCase()}`}>
                        {withdrawal.withdrawalStatus}
                      </span>
                    </td>
                    <td>
                      {withdrawal.withdrawalStatus === 'PENDING' && (
                        <div className="adminpage-actions">
                          <button 
                            className="adminpage-action-button adminpage-approve"
                            onClick={() => handleApproveWithdrawal(withdrawal.id)}
                          >
                            <FaCheckCircle /> Approve
                          </button>
                          <button 
                            className="adminpage-action-button adminpage-decline"
                            onClick={() => handleDeclineWithdrawal(withdrawal.id)}
                          >
                            <FaTimesCircle /> Decline
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
          </table>
          <div className="adminpage-pagination">
            <button 
              onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
              disabled={currentPage === 0}
            >
              Previous
            </button>
            <span>Page {currentPage + 1}</span>
            <button 
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={withdrawals.length < itemsPerPage}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
  );


  const renderUsers = () => {
    return (
      <div className="adminpage-card">
        <div className="adminpage-card-header">
          <div className="adminpage-header-content">
            <h5>User Management</h5>
            <div className="adminpage-header-actions">
              <div className="adminpage-search">
                <input
                  type="text"
                  placeholder="Search by name, email, or mobile..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <button className="adminpage-btn-primary">
                  <FaSearch />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="adminpage-card-body">
          {isLoading ? (
            <div className="adminpage-loading">Loading...</div>
          ) : error ? (
            <div className="adminpage-error">{error}</div>
          ) : (
            <div className="adminpage-table-container">
              <table className="adminpage-table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Mobile</th>
                    <th>Account Status</th>
                    <th>Wallet Balance</th>
                    <th>Created On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map(user => (
                    <tr key={user.id}>
                      <td>
                        <div className="adminpage-user-info">
                          <div className="adminpage-user-avatar">
                            <FaUser className="adminpage-avatar-icon" />
                          </div>
                          <div className="adminpage-user-details">
                            <div className="adminpage-user-name">{user.fullName}</div>
                            <div className="adminpage-user-email">{user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td>{user.mobile}</td>
                      <td>
                        <span className={`adminpage-badge ${user.accountStatus === 'ACTIVE' ? 'adminpage-badge-success' : 'adminpage-badge-warning'}`}>
                          {user.accountStatus}
                        </span>
                      </td>
                      <td>KSH {user.wallet?.toLocaleString()}</td>
                      <td>{format(parseISO(user.createdOn), 'PPp')}</td>
                      <td>
                        <div className="adminpage-actions">
                          <button 
                            className="adminpage-btn-primary adminpage-btn-sm"
                            onClick={() => handleEditUser(user)}
                          >
                            <FaEdit /> Edit
                          </button>
                          <button className="adminpage-btn-info adminpage-btn-sm">
                            <FaEye /> View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="adminpage-pagination">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <span>Page {currentPage + 1}</span>
                <button
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  disabled={currentUsers.length < itemsPerPage}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  
  

  return (
    <div className="adminpage-container">
      <aside className={`adminpage-sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="adminpage-sidebar-header">
          <img src="/apple-logo.png" alt="Logo" className="adminpage-logo" />
          <h4>Vanguard Apple</h4>
          {isMobileView && (
            <button className="adminpage-sidebar-close" onClick={toggleSidebar}>
              <FaTimes />
            </button>
          )}
        </div>
        
        <nav className="adminpage-nav">
          {[
            { icon: <FaTachometerAlt />, label: 'Dashboard' },
            { icon: <FaChartBar />, label: 'Investments' },
            { icon: <FaCreditCard />, label: 'Deposits' },
            { icon: <FaMoneyBillWave />, label: 'Withdrawals' },
            { icon: <FaUsers />, label: 'Users' },
            { icon: <FaLightbulb />, label: 'Daily' },
            { icon: <FaReceipt />, label: 'Proofs' },
            { icon: <FaFileContract />, label: 'Reports' },
            { icon: <FaRobot />, label: 'Products' },
            { icon: <FaUserShield />, label: 'Security' },
            { icon: <FaCog />, label: 'Settings' }
          ].map(item => (
            <button 
              key={item.label}
              className={`adminpage-nav-item ${activeTab === item.label.toLowerCase() ? 'active' : ''}`}
              onClick={() => setActiveTab(item.label.toLowerCase())}
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          ))}
        </nav>
        
        <div className="adminpage-sidebar-footer">
          <div className="adminpage-admin-info">
            <div className="adminpage-admin-avatar">
              <FaUserShield />
            </div>
            <div className="adminpage-admin-details">
              <div className="adminpage-admin-name">Admin User</div>
              <div className="adminpage-admin-email">admin@vanguard.com</div>
            </div>
          </div>
          <button className="adminpage-logout-btn" onClick={logout}>
            <FaSignOutAlt />
          </button>
        </div>
      </aside>

      {isMobileView && isSidebarOpen && (
        <div 
          className="adminpage-sidebar-overlay"
          onClick={toggleSidebar}
        />
      )}
  
      <main className={`adminpage-main ${isSidebarOpen && !isMobileView ? 'shifted' : ''}`}>
        <header className="adminpage-header">
          <div className="adminpage-header-left">
            <button 
              className="adminpage-mobile-toggle"
              onClick={toggleSidebar}
            >
              <FaBars />
            </button>
            <h5>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h5>
          </div>
          <div className="adminpage-header-actions">
            <button className="adminpage-notification-btn">
              <FaBell />
              {notifications > 0 && (
                <span className="adminpage-notification-badge">
                  {notifications}
                </span>
              )}
            </button>
            <button className="adminpage-globe-btn">
              <FaGlobe />
            </button>
          </div>
        </header>
  
        <div className="adminpage-content">
          {activeTab === 'dashboard' && renderDashboard()}
          {activeTab === 'deposits' && renderDeposits()}
          {activeTab === 'withdrawals' && renderWithdrawals()}
          {activeTab === 'users' && renderUsers()}
          {activeTab === 'daily' && <DailyRewards />}
          {activeTab === 'proofs' && <AdminProofs />}
          {activeTab === 'products' && <AddProduct />}
        </div>
      </main>
  
  {editMode && (
    <div className="adminpage-modal-overlay">
      <div className="adminpage-modal">
        <div className="adminpage-modal-header">
          <h5>Edit User Details</h5>
          <button className="adminpage-modal-close" onClick={() => setEditMode(false)}>×</button>
        </div>
        <div className="adminpage-modal-body">
          <div className="adminpage-form-group">
            <label>Name</label>
            <input type="text" value={selectedUser?.fullName} disabled />
          </div>
          <div className="adminpage-form-group">
            <label>Email</label>
            <input type="email" value={selectedUser?.email} disabled />
          </div>
          <div className="adminpage-form-group">
            <label>Wallet Balance</label>
            <div className="adminpage-input-group">
              <span>KSH</span>
              <input
                type="number"
                value={editedBalance}
                onChange={(e) => setEditedBalance(e.target.value)}
              />
            </div>
          </div>
          <div className="adminpage-form-group">
            <label>Account Status</label>
            <select 
              value={selectedUser?.accountStatus} 
              onChange={(e) => setSelectedUser({
                ...selectedUser,
                accountStatus: e.target.value
              })}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="SUSPENDED">Suspended</option>
            </select>
          </div>
        </div>
        <div className="adminpage-modal-footer">
          <button className="adminpage-btn-light" onClick={() => setEditMode(false)}>
            Cancel
          </button>
          <button 
            className="adminpage-btn-primary" 
            onClick={() => handleUpdateUser(selectedUser.id, {
              wallet: parseFloat(editedBalance),
              accountStatus: selectedUser.accountStatus
            })}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  )}
    </div>
  );
};

export default Admin;