import React, { useState } from 'react';
import { FaUser, FaClipboard, FaCashRegister, FaHeadset, FaLock, FaCommentAlt, FaInfoCircle, FaFileAlt, FaDownload, FaArrowLeft } from 'react-icons/fa';
import BottomNavigation from './BottomNavigation';
import { useAuth } from '../security/security/AuthContext';
import { Link } from 'react-router-dom';
import DepositRecord from './DepositRecord';
import CashoutView from './CashoutView';
import WithdrawalRecord from './WithdrawalRecord';
import ChangePassword from './ChangePassword';

const Me = () => {
  const [currentView, setCurrentView] = useState('main');
  const { userDetails, token } = useAuth();
  const authContext = useAuth();
  const [toastNotification, setToastNotification] = useState(null);
  



  const features = [
    { name: "Deposit record", icon: <FaClipboard />, view: 'deposit' },
    { name: "Withdrawal record", icon: <FaCashRegister />, view: 'withdrawal' },
    { name: "Customer service", icon: <FaHeadset />, view: 'customer' },
    { name: "Change password", icon: <FaLock />, view: 'password' },
    // { name: "Feedback", icon: <FaCommentAlt />, view: 'feedback' },
    { name: "About Us", icon: <FaInfoCircle />, view: 'about' },
    { name: "Term of use", icon: <FaFileAlt />, view: 'terms' },
    { name: "APP Download", icon: <FaDownload />, view: 'download' }
  ];

  

  function logout() {
    authContext.logout();
}


const joinTelegramChannel = () => {
  const telegramLink = "https://t.me/+IPtxKUMZmXU4ZmVk";
  window.open(telegramLink, '_blank');
};
const whatsappservice = () => {
  const whatsappLink = "https://wa.link/wzy3qh";
  window.open(whatsappLink, '_blank');
};

const calculateTotalBalance = () => {
  const wallet = Number(userDetails.wallet) || 0;
  const invitationIncome = Number(userDetails.invitationIncome) || 0;
  return (wallet + invitationIncome).toLocaleString();
};

  const renderMainView = () => (
    <>
      <div className="user-info">
        <FaUser className="user-icon" />
        <div>
          <p className="phone-number">{userDetails.mobile}</p>
          <p className="invitation-code">Invitation code: {userDetails.invitationCode}</p>
        </div>
      </div>

            <div className="balance-card">
              <div>
                <h3>Balance</h3>
                <h2>{calculateTotalBalance()}</h2>
              </div>
              <button className="wallet-btn" onClick={() => setCurrentView('wallet')}>
                My Wallet
              </button>
           </div>

      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-item" onClick={() => setCurrentView(feature.view)}>
            {feature.icon}
            <p>{feature.name}</p>
          </div>
        ))}
      </div>
      <Link to={"/login"}>
      <button className="logout-btn" onClick={logout}>Logout</button>
      </Link>
    </>
  );

  const renderDepositRecord = () => (
    <div className="record-view">
      <DepositRecord userDetails={userDetails} token={authContext.token} />
    </div>
  );

  const renderWithdrawalRecord = () => (
    <div className="record-view">
      <WithdrawalRecord userDetails={userDetails} token={token} />
    </div>
  );

  const renderCustomerService = () => (
    <div className="customer-service-view">
      <h2>Customer Service</h2>
      <div className="service-options">
        <button className="service-btn telegram"   onClick={joinTelegramChannel}>Telegram Channel</button>
        <button className="service-btn whatsapp" onClick={whatsappservice}>WhatsApp Service</button>
      </div>
    </div>
  );

  const renderChangePassword = () => (
    <div className="change-password-view">
      <ChangePassword token={token} />
    </div>
  );

  const renderFeedback = () => (
    <div className="feedback-view">
      <h2>Feedback</h2>
      <form className="feedback-form">
        <input type="text" placeholder="WhatsApp" />
        <input type="email" placeholder="Email" />
        <textarea placeholder="Describe your feedback"></textarea>
        <div className="image-upload">
          <p>Picture</p>
          <div className="upload-box">
            <FaDownload />
            <p>Upload Image</p>
          </div>
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );

  const renderAboutUs = () => (
    <div className="mepage-about-us-view">
      <div className="mepage-about-us-content">
        <img src="/apple-logo.png" alt="App Logo" className="mepage-app-logo" />
        <h1>Vanguard Apple Holdings</h1>
        <div className="mepage-divider"></div>
  
        <h2>About Us</h2>
        <p>
          Vanguard Apple Holdings is a global investment firm specializing in groundbreaking technology ventures. Founded
          on the principles of innovation and forward-thinking, we have become a leader in the investment world, particularly
          in top-tier Apple investments. Led by visionary CEO Timothy D. Cook, Vanguard Apple Holdings has been instrumental
          in shaping the landscape of modern technology, investing in pioneering companies that redefine how we interact with
          computers, mobile devices, and digital services.
        </p>
  
        <h2>Key Investments and Contributions</h2>
        <p>
          1. Vanguard Apple Holdings has played a pivotal role in fostering the growth of Apple Inc., the iconic American multinational
          technology company. From the revolutionary Mac computers to the game-changing iPhone and iPad, Vanguard Apple Holdings
          has been at the forefront of investing in Apple's innovative products that redefine industries and shape consumer behavior.
        </p>
        <p>
          2. Vanguard Apple Holdings has also been instrumental in supporting Apple's ecosystem, including its App Store, iCloud, and
          Apple TV+, which have transformed the way we consume media, communicate, and access digital services.
        </p>
  
        <h2>Research & Innovation</h2>
        <div className="mepage-stats-grid">
          <div className="mepage-stat-item">
            <h3>$977.3B</h3>
            <p>R&D Investment</p>
          </div>
          <div className="mepage-stat-item">
            <h3>55.4%</h3>
            <p>R&D Workforce</p>
          </div>
          <div className="mepage-stat-item">
            <h3>120,000+</h3>
            <p>Active Patents</p>
          </div>
        </div>
        <p>
          At Vanguard Apple Holdings, our commitment to research and innovation is unwavering. We understand that technological
          advancements are the cornerstone of progress, and that's why we've dedicated substantial resources to push the boundaries
          of innovation forward.
        </p>
  
        <h2>Openness, Collaboration, and Shared Success</h2>
        <p>
          Vanguard Apple Holdings believes in fostering an environment of openness and collaboration, where ideas converge and innovations
          flourish. We've witnessed a paradigm shift in global industry dynamics, and we've actively embraced these changes to drive
          collective progress.
        </p>
  
        <h2>Milestones</h2>
        <p>
          In 2022, Vanguard Apple Holdings marked a significant milestone with the release of the groundbreaking "Lighting up the Apple Era"
          initiative, unveiling scenario-based solutions across industries, from Smart Mobility to Digital Healthcare and Sustainable Energy.
        </p>
  
        <h2>Quality Policy</h2>
        <p>
          At Vanguard Apple Holdings, quality is not just a benchmark; it's our ethos. We strive to communicate our customers' requirements
          accurately, fostering collaboration and driving continuous improvement across the value chain.
        </p>
      </div>
    </div>
  );
  

  const renderTermsOfService = () => (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Terms of Use</h1>
      </header>
      <div className="terms-content">
        <section>
          <h2>1. ACCEPTANCE OF TERMS</h2>
          <p>
            By accessing or using the VanguardAfriHoldings app, you agree to comply with these Terms of Use and all applicable laws and regulations. If you do not agree with any part of these terms, please refrain from using the app. These terms constitute a binding agreement between you and VanguardAfriHoldings.
          </p>
        </section>
  
        <section>
          <h2>2. INVESTMENT RISKS</h2>
          <p>
            Investing involves inherent risks. Past performance of securities, industries, sectors, markets, or financial products is not indicative of future results or returns. By using this app, you acknowledge and accept that investing may result in the loss of some or all of your investment. You should carefully consider your investment objectives and seek independent financial advice before making any investment decisions.
          </p>
        </section>
  
        <section>
          <h2>3. USER RESPONSIBILITIES</h2>
          <p>
            You are responsible for maintaining the confidentiality of your account credentials, including your password, and for managing access to your device. You agree to accept responsibility for all activities that occur under your account. Please notify us immediately of any unauthorized use or security breach. It is your responsibility to ensure that the information you provide is accurate, complete, and up-to-date.
          </p>
        </section>
  
        <section>
          <h2>4. PROHIBITED ACTIVITIES</h2>
          <p>
            When using our app, you agree not to engage in activities that include, but are not limited to:
            (a) Violating any applicable laws or regulations;
            (b) Infringing on the intellectual property rights of others;
            (c) Transmitting or distributing content that is unlawful, harmful, or otherwise objectionable;
            (d) Interfering with the operation or security of the app, our servers, or networks;
            (e) Engaging in fraudulent activities, including impersonation or providing false information;
            (f) Attempting to access or tamper with non-public areas of the app or systems of VanguardAfriHoldings.
          </p>
        </section>
  
        <section>
          <h2>5. PRIVACY AND DATA COLLECTION</h2>
          <p>
            Your privacy is important to us. By using this app, you agree to the collection, use, and sharing of your information as described in our Privacy Policy, which is available on our website. We use your data to provide, improve, and personalize our services. Please review our Privacy Policy for more information on how your personal information is handled.
          </p>
        </section>
  
        <section>
          <h2>6. THIRD-PARTY LINKS</h2>
          <p>
            The VanguardAfriHoldings app may contain links to third-party websites or services that are not owned or controlled by VanguardAfriHoldings. We are not responsible for the content, privacy policies, or practices of any third-party sites or services. You acknowledge and agree that VanguardAfriHoldings shall not be liable for any damages or losses caused by or in connection with your use of any third-party website or service.
          </p>
        </section>
  
        <section>
          <h2>7. LIMITATION OF LIABILITY</h2>
          <p>
            To the fullest extent permitted by law, VanguardAfriHoldings shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use or inability to use the app, including, but not limited to, loss of investment, loss of data, or business interruption. This limitation of liability applies even if we have been advised of the possibility of such damages.
          </p>
        </section>
  
        <section>
          <h2>8. NO FINANCIAL ADVICE</h2>
          <p>
            The content provided on the VanguardAfriHoldings app is for informational purposes only and should not be considered financial, investment, or legal advice. You should consult with a qualified financial advisor before making any investment decisions. VanguardAfriHoldings does not guarantee the accuracy, completeness, or timeliness of information provided through the app.
          </p>
        </section>
  
        <section>
          <h2>9. MODIFICATIONS TO TERMS</h2>
          <p>
            VanguardAfriHoldings reserves the right to amend or update these Terms of Use at any time. Any changes will be posted on this page, and we will notify you of significant updates. Continued use of the app following such modifications constitutes your acknowledgment and acceptance of the revised Terms of Use. It is your responsibility to review the Terms of Use periodically.
          </p>
        </section>
  
        <section>
          <h2>10. TERMINATION</h2>
          <p>
            VanguardAfriHoldings reserves the right to suspend or terminate your access to the app at any time, with or without notice, for violating these Terms of Use or for any other reason deemed appropriate by us. Upon termination, all rights and licenses granted to you will cease immediately.
          </p>
        </section>
  
        <section>
          <h2>11. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
          <p>
            These Terms of Use shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles. Any disputes arising from or relating to these terms or your use of the app shall be resolved through binding arbitration in [Jurisdiction], unless otherwise required by applicable law.
          </p>
        </section>
  
        <section>
          <h2>12. CONTACT INFORMATION</h2>
          <p>
            If you have any questions or concerns about these Terms of Use or the VanguardAfriHoldings app, please contact us.
          </p>
        </section>
      </div>
    </div>
  );
  

  const renderCashoutView = () => (
    <CashoutView 
      setCurrentView={setCurrentView}
      userDetails={userDetails}
      token={token}
      calculateTotalBalance={calculateTotalBalance}
      setToastNotification={setToastNotification}
    />
  );


  const renderView = () => {
    switch (currentView) {
      case 'deposit':
        return renderDepositRecord();
      case 'withdrawal':
        return renderWithdrawalRecord();
      case 'customer':
        return renderCustomerService();
      case 'password':
        return renderChangePassword();
      case 'feedback':
        return renderFeedback();
      case 'about':
        return renderAboutUs();
      case 'terms':
        return renderTermsOfService();
      case 'download':
        return <div className="info-view"><h2>APP Download</h2><p>Download link will be available Soon!</p></div>;
      case 'wallet':
        return renderCashoutView();
      default:
        return renderMainView();
    }
  };

  return (
    <div className="me-container">
      {currentView !== 'main' && (
        <button className="back-btn" onClick={() => setCurrentView('main')}>
          <FaArrowLeft /> Back
        </button>
      )}
      {renderView()}
      <BottomNavigation />
    </div>
  );
};

export default Me;
