// LoadingLogin.jsx
import React from 'react';

const LoadingLogin = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Signing in...</p>
      </div>
    </div>
  );
};

export default LoadingLogin;
