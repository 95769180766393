import React, { useState, useEffect } from 'react';
import { ArrowUpCircle, ArrowDownCircle, Activity } from 'lucide-react';
import './style.css';

const mockTransactions = [
  { id: 1, type: 'deposit', amount: 1000, user: 'John D.' },
  { id: 2, type: 'withdrawal', amount: 500, user: 'Sarah M.' },
  { id: 3, type: 'deposit', amount: 2000, user: 'Mike R.' },
  { id: 4, type: 'withdrawal', amount: 1500, user: 'Emma S.' },
  { id: 5, type: 'deposit', amount: 3000, user: 'Alex K.' },
  { id: 6, type: 'deposit', amount: 1200, user: 'Lisa T.' },
  { id: 7, type: 'withdrawal', amount: 800, user: 'Tom W.' },
  { id: 8, type: 'deposit', amount: 2500, user: 'Anna P.' },
  { id: 9, type: 'withdrawal', amount: 700, user: 'Chris L.' },
  { id: 10, type: 'deposit', amount: 1800, user: 'Olivia N.' },
  { id: 11, type: 'deposit', amount: 2300, user: 'David G.' },
  { id: 12, type: 'withdrawal', amount: 1100, user: 'Sophie B.' },
  { id: 13, type: 'deposit', amount: 3500, user: 'Ryan H.' },
  { id: 14, type: 'withdrawal', amount: 600, user: 'Emily C.' },
  { id: 15, type: 'deposit', amount: 1500, user: 'James F.' },
  { id: 16, type: 'deposit', amount: 2200, user: 'Mia Z.' },
  { id: 17, type: 'withdrawal', amount: 1200, user: 'Daniel Y.' },
  { id: 18, type: 'deposit', amount: 1700, user: 'Sophia X.' },
  { id: 19, type: 'withdrawal', amount: 900, user: 'Ethan V.' },
  { id: 20, type: 'deposit', amount: 2800, user: 'Isabella U.' },
  { id: 21, type: 'withdrawal', amount: 1600, user: 'Lucas P.' },
  { id: 22, type: 'deposit', amount: 4000, user: 'Grace Q.' },
  { id: 23, type: 'withdrawal', amount: 750, user: 'William R.' },
  { id: 24, type: 'deposit', amount: 2100, user: 'Victoria S.' },
  { id: 25, type: 'withdrawal', amount: 950, user: 'Henry T.' },
  { id: 26, type: 'deposit', amount: 3200, user: 'Zoe U.' },
  { id: 27, type: 'withdrawal', amount: 1400, user: 'Benjamin V.' },
  { id: 28, type: 'deposit', amount: 1900, user: 'Charlotte W.' },
  { id: 29, type: 'withdrawal', amount: 850, user: 'Samuel X.' },
  { id: 30, type: 'deposit', amount: 2600, user: 'Abigail Y.' },
  { id: 31, type: 'withdrawal', amount: 1300, user: 'Matthew Z.' },
  { id: 32, type: 'deposit', amount: 3100, user: 'Ella A.' },
  { id: 33, type: 'withdrawal', amount: 720, user: 'Joseph B.' },
  { id: 34, type: 'deposit', amount: 2400, user: 'Scarlett C.' },
  { id: 35, type: 'withdrawal', amount: 980, user: 'Andrew D.' },
  { id: 36, type: 'deposit', amount: 3300, user: 'Chloe E.' },
  { id: 37, type: 'withdrawal', amount: 1450, user: 'Christopher F.' },
  { id: 38, type: 'deposit', amount: 1850, user: 'Lucy G.' },
  { id: 39, type: 'withdrawal', amount: 880, user: 'Jack H.' },
  { id: 40, type: 'deposit', amount: 2700, user: 'Hannah I.' },
  { id: 41, type: 'withdrawal', amount: 1250, user: 'Owen J.' },
  { id: 42, type: 'deposit', amount: 3400, user: 'Aria K.' },
  { id: 43, type: 'withdrawal', amount: 690, user: 'Sebastian L.' },
  { id: 44, type: 'deposit', amount: 2050, user: 'Lily M.' },
  { id: 45, type: 'withdrawal', amount: 920, user: 'Gabriel N.' },
  { id: 46, type: 'deposit', amount: 3600, user: 'Audrey O.' },
  { id: 47, type: 'withdrawal', amount: 1550, user: 'Julian P.' },
  { id: 48, type: 'deposit', amount: 1750, user: 'Nora Q.' },
  { id: 49, type: 'withdrawal', amount: 830, user: 'Isaac R.' },
  { id: 50, type: 'deposit', amount: 2900, user: 'Aurora S.' },
  { id: 51, type: 'withdrawal', amount: 1150, user: 'Leo T.' },
  { id: 52, type: 'deposit', amount: 3700, user: 'Hazel U.' },
  { id: 53, type: 'withdrawal', amount: 670, user: 'Theodore V.' },
  { id: 54, type: 'deposit', amount: 2150, user: 'Bella W.' },
  { id: 55, type: 'withdrawal', amount: 940, user: 'Hudson X.' },
  { id: 56, type: 'deposit', amount: 3800, user: 'Stella Y.' },
  { id: 57, type: 'withdrawal', amount: 1650, user: 'Adrian Z.' },
  { id: 58, type: 'deposit', amount: 1650, user: 'Maya A.' },
  { id: 59, type: 'withdrawal', amount: 860, user: 'Marcus B.' },
  { id: 60, type: 'deposit', amount: 3000, user: 'Ruby C.' },
  { id: 61, type: 'withdrawal', amount: 1050, user: 'Felix D.' },
  { id: 62, type: 'deposit', amount: 3900, user: 'Clara E.' },
  { id: 63, type: 'withdrawal', amount: 650, user: 'Miles F.' },
  { id: 64, type: 'deposit', amount: 2250, user: 'Violet G.' },
  { id: 65, type: 'withdrawal', amount: 960, user: 'Nathan H.' },
  { id: 66, type: 'deposit', amount: 4100, user: 'Alice I.' },
  { id: 67, type: 'withdrawal', amount: 1750, user: 'Oscar J.' },
  { id: 68, type: 'deposit', amount: 1550, user: 'Eden K.' },
  { id: 69, type: 'withdrawal', amount: 890, user: 'Maxwell L.' },
  { id: 70, type: 'deposit', amount: 3100, user: 'Delilah M.' },
  { id: 71, type: 'withdrawal', amount: 950, user: 'Silas N.' },
  { id: 72, type: 'deposit', amount: 4200, user: 'Ivy O.' },
  { id: 73, type: 'withdrawal', amount: 630, user: 'Jasper P.' },
  { id: 74, type: 'deposit', amount: 2350, user: 'Willow Q.' },
  { id: 75, type: 'withdrawal', amount: 980, user: 'Xavier R.' },
  { id: 76, type: 'deposit', amount: 4300, user: 'Piper S.' },
  { id: 77, type: 'withdrawal', amount: 1850, user: 'Atlas T.' },
  { id: 78, type: 'deposit', amount: 1450, user: 'Edu U.' },
  { id: 79, type: 'withdrawal', amount: 920, user: 'Leonard V.' },
  { id: 80, type: 'deposit', amount: 3200, user: 'Okoth W.' },
  { id: 81, type: 'withdrawal', amount: 850, user: 'Finn X.' },
  { id: 82, type: 'deposit', amount: 4400, user: 'Sage Y.' },
  { id: 83, type: 'withdrawal', amount: 610, user: 'John Z.' },
  { id: 84, type: 'deposit', amount: 2450, user: 'Luna A.' },
  { id: 85, type: 'withdrawal', amount: 1000, user: 'Atlas B.' },
  { id: 86, type: 'deposit', amount: 4500, user: 'Blair C.' },
  { id: 87, type: 'withdrawal', amount: 1950, user: 'Kai D.' },
  { id: 88, type: 'deposit', amount: 1350, user: 'Quinn E.' },
  { id: 89, type: 'withdrawal', amount: 950, user: 'Axel F.' },
  { id: 90, type: 'deposit', amount: 3300, user: 'Omondi G.' },
  { id: 91, type: 'deposit', amount: 3800, user: 'Robert P.' },
  { id: 92, type: 'withdrawal', amount: 1120, user: 'Jennifer K.' },
  { id: 93, type: 'deposit', amount: 4600, user: 'Michael B.' },
  { id: 94, type: 'withdrawal', amount: 580, user: 'Jessica H.' },
  { id: 95, type: 'deposit', amount: 2550, user: 'William T.' },
  { id: 96, type: 'withdrawal', amount: 1020, user: 'Elizabeth R.' },
  { id: 97, type: 'deposit', amount: 4700, user: 'Richard M.' },
  { id: 98, type: 'withdrawal', amount: 2050, user: 'Susan C.' },
  { id: 99, type: 'deposit', amount: 1250, user: 'Thomas W.' },
  { id: 100, type: 'withdrawal', amount: 980, user: 'Patricia L.' },
  { id: 101, type: 'deposit', amount: 3400, user: 'Charles B.' },
  { id: 102, type: 'withdrawal', amount: 750, user: 'Linda D.' },
  { id: 103, type: 'deposit', amount: 4800, user: 'Daniel S.' },
  { id: 104, type: 'withdrawal', amount: 590, user: 'Barbara A.' },
  { id: 105, type: 'deposit', amount: 2650, user: 'Paul R.' },
  { id: 106, type: 'withdrawal', amount: 1040, user: 'Margaret J.' },
  { id: 107, type: 'deposit', amount: 4900, user: 'Mark H.' },
  { id: 108, type: 'withdrawal', amount: 2150, user: 'Sandra M.' },
  { id: 109, type: 'deposit', amount: 1150, user: 'Donald F.' },
  { id: 110, type: 'withdrawal', amount: 990, user: 'Michelle W.' },
  { id: 111, type: 'deposit', amount: 3500, user: 'George B.' },
  { id: 112, type: 'withdrawal', amount: 760, user: 'Nancy T.' },
  { id: 113, type: 'deposit', amount: 5000, user: 'Kenneth C.' },
  { id: 114, type: 'withdrawal', amount: 600, user: 'Lisa R.' },
  { id: 115, type: 'deposit', amount: 2750, user: 'Steven K.' },
  { id: 116, type: 'withdrawal', amount: 1060, user: 'Carol M.' },
  { id: 117, type: 'deposit', amount: 5100, user: 'Edward J.' },
  { id: 118, type: 'withdrawal', amount: 2250, user: 'Helen W.' },
  { id: 119, type: 'deposit', amount: 1050, user: 'Ronald L.' },
  { id: 120, type: 'withdrawal', amount: 1010, user: 'Ruth S.' },
  { id: 121, type: 'deposit', amount: 3600, user: 'Jason M.' },
  { id: 122, type: 'withdrawal', amount: 770, user: 'Sharon D.' },
  { id: 123, type: 'deposit', amount: 5200, user: 'Jeffrey R.' },
  { id: 124, type: 'withdrawal', amount: 610, user: 'Amy B.' },
  { id: 125, type: 'deposit', amount: 2850, user: 'Gary T.' },
  { id: 126, type: 'withdrawal', amount: 1080, user: 'Deborah H.' },
  { id: 127, type: 'deposit', amount: 5300, user: 'Frank W.' },
  { id: 128, type: 'withdrawal', amount: 2350, user: 'Diane L.' },
  { id: 129, type: 'deposit', amount: 950, user: 'Dennis M.' },
  { id: 130, type: 'withdrawal', amount: 1030, user: 'Gloria S.' },
  { id: 131, type: 'deposit', amount: 3700, user: 'Peter C.' },
  { id: 132, type: 'withdrawal', amount: 780, user: 'Julie A.' },
  { id: 133, type: 'deposit', amount: 5400, user: 'Raymond B.' },
  { id: 134, type: 'withdrawal', amount: 620, user: 'Karen M.' },
  { id: 135, type: 'deposit', amount: 2950, user: 'Harold P.' },
  { id: 136, type: 'withdrawal', amount: 1100, user: 'Joyce T.' },
  { id: 137, type: 'deposit', amount: 5500, user: 'Roger N.' },
  { id: 138, type: 'withdrawal', amount: 2450, user: 'Teresa K.' },
  { id: 139, type: 'deposit', amount: 850, user: 'Larry R.' },
  { id: 140, type: 'withdrawal', amount: 1050, user: 'Maria S.' },
  { id: 141, type: 'deposit', amount: 3800, user: 'Jerry L.' },
  { id: 142, type: 'withdrawal', amount: 790, user: 'Brenda W.' },
  { id: 143, type: 'deposit', amount: 5600, user: 'Keith M.' },
  { id: 144, type: 'withdrawal', amount: 630, user: 'Pamela H.' },
  { id: 145, type: 'deposit', amount: 3050, user: 'Scott B.' },
  { id: 146, type: 'withdrawal', amount: 1120, user: 'Rachel T.' },
  { id: 147, type: 'deposit', amount: 5700, user: 'Wayne C.' },
  { id: 148, type: 'withdrawal', amount: 2550, user: 'Martha D.' },
  { id: 149, type: 'deposit', amount: 750, user: 'Bruce K.' },
  { id: 150, type: 'withdrawal', amount: 1070, user: 'Angela M.' }
];

const LiveTransactionsBot = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Show initial loading state
    setTimeout(() => {
      const initialTransaction = mockTransactions[Math.floor(Math.random() * mockTransactions.length)];
      setTransactions([{ ...initialTransaction, id: Date.now() }]);
      setIsLoading(false);
    }, 1000);

    const interval = setInterval(() => {
      const randomTransaction = mockTransactions[Math.floor(Math.random() * mockTransactions.length)];
      setTransactions(prev => {
        const newTransactions = [...prev, { 
          ...randomTransaction, 
          id: Date.now(),
          time: '1 min ago'
        }];
        return newTransactions.slice(-2); // Keep last2 transactions
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="live-transactions-card">
        <div className="loading-container">
          <div className="loading-spinner" />
          <span className="loading-text">Loading transactions...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="live-transactions-card">
      <div className="card-header">
        <h3 className="card-title">Live Transactions</h3>
        <div className="live-badge">
          <span className="live-indicator"></span>
          Live
        </div>
      </div>
      
      <div className="transactions-container">
        <div className="transaction-list">
          {transactions.map((transaction) => (
            <div key={transaction.id} className="transaction-item">
              <div className="transaction-left">
                <div className={`transaction-icon ${transaction.type}`}>
                  {transaction.type === 'deposit' ? (
                    <ArrowUpCircle size={24} />
                  ) : (
                    <ArrowDownCircle size={24} />
                  )}
                </div>
                <div className="transaction-details">
                  <span className="transaction-user">{transaction.user}</span>
                  <span className="transaction-time">{transaction.time}</span>
                </div>
              </div>
              <span className={`transaction-amount ${transaction.type}`}>
                {transaction.type === 'deposit' ? '+' : '-'} KES {transaction.amount.toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LiveTransactionsBot;