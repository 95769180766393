import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './auth.css';

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Get email from navigation state or fallback to localStorage
    const userEmail = location.state?.email || localStorage.getItem('pendingVerificationEmail');
    if (userEmail) {
      setEmail(userEmail);
      localStorage.setItem('pendingVerificationEmail', userEmail);
    } else {
      // Redirect to signup if no email is found
      navigate('/signup');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    let interval;
    if (timer > 0 && !canResend) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }

    return () => clearInterval(interval);
  }, [timer, canResend]);

  const handleResendEmail = async () => {
    try {
      // Call your backend API to resend verification email
      const response = await fetch('https://api.vanguardafriholdings.com/register/resend-verification-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Verification email resent successfully!');
        setTimer(60);
        setCanResend(false);
      } else {
        setMessage('Please Chcek your inbox or Spam to resend link.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="verify-email-container">
      <div className="verify-email-card">
        <div className="email-icon">
          <div className="envelope">
            <div className="envelope-top"></div>
            <div className="envelope-body"></div>
            <div className="letter">
              <div className="letter-line"></div>
              <div className="letter-line"></div>
              <div className="letter-line"></div>
            </div>
          </div>
        </div>
        
        <h1>Check Your Email</h1>
        <div className="email-info">
          We've sent a verification link to:
          <span className="user-email">{email}</span>
        </div>
        
        <div className="verification-steps">
          <h3>Next steps:</h3>
          <ol>
            <li>Open the email from Vanguard Apple Holdings</li>
            <li>Click the verification button in the email to verify</li>
          </ol>
        </div>

        <div className="resend-section">
          {message && <div className="message">{message}</div>}
          {!canResend ? (
            <p>You can resend the email in {timer} seconds</p>
          ) : (
            <button 
              className="resend-button" 
              onClick={handleResendEmail}
            >
              Resend Verification Email
            </button>
          )}
        </div>

        <div className="help-section">
          <p>Having trouble? Make sure to:</p>
          <ul>
            <li><strong>Check your spam or junk folder</strong></li>
            <li>Verify you entered the correct email address</li>
            <li>Receiving the email might take upto 3 minutes max.</li>
          </ul>
        </div>

        <div className="bottom-links">
          <button 
            className="contact-support"
            onClick={() => window.location.href = 'mailto:info@vanguardafriholdings.com'}
          >
            Contact Support
          </button>
          <button 
            className="return-home"
            onClick={() => navigate('/login')}
          >
            Return to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;