import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DailyRewards = () => {
  const [currentReward, setCurrentReward] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrentReward = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://api.vanguardafriholdings.com/api/admin/rewards/current');
        setCurrentReward(response.data);
      } catch (err) {
        setError('Failed to fetch current reward');
        console.error('Error fetching current reward:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentReward();
  }, []);

  const generateNewReward = async () => {
    try {
      setIsLoading(true);
      await axios.post('https://api.vanguardafriholdings.com/api/admin/rewards/generate');
      setCurrentReward(null); // Reset the current reward
      fetchCurrentReward(); // Fetch the new reward
    } catch (err) {
      setError('Failed to generate new reward');
      console.error('Error generating new reward:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrentReward = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('https://api.vanguardafriholdings.com/api/admin/rewards/current');
      setCurrentReward(response.data);
    } catch (err) {
      setError('Failed to fetch current reward');
      console.error('Error fetching current reward:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-page-container">
      <div className="admin-page-header">
        <h2>Daily Rewards</h2>
      </div>
      <div className="admin-page-content">
        {isLoading ? (
          <div className="admin-loading">Loading...</div>
        ) : error ? (
          <div className="admin-error">{error}</div>
        ) : currentReward ? (
          <div className="admin-reward-card">
            <div className="admin-reward-code">
              <h3>Current Reward Code</h3>
              <span className="admin-reward-code-value">{currentReward.rewardCode}</span>
            </div>
            <div className="admin-reward-amount">
              <h3>Reward Amount</h3>
              <span className="admin-reward-amount-value">KSH {currentReward.rewardAmount.toFixed(2)}</span>
            </div>
            <div className="admin-reward-expiry">
              <h3>Expires At</h3>
              <span className="admin-reward-expiry-value">{new Date(currentReward.expiresAt).toLocaleString()}</span>
            </div>
            <button className="admin-btn admin-btn-primary" onClick={generateNewReward}>
              Generate New Reward
            </button>
          </div>
        ) : (
          <button className="admin-btn admin-btn-primary" onClick={generateNewReward}>
            Generate Daily Reward
          </button>
        )}
      </div>
    </div>
  );
};

export default DailyRewards;