import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaTimes } from 'react-icons/fa';

const HomeToastNotification = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div className={`hometoastnotification-container ${type}`}>
      <div className="hometoastnotification-icon">
        {type === 'success' ? <FaCheckCircle /> : <FaExclamationCircle />}
      </div>
      <div className="hometoastnotification-message">{message}</div>
      <button className="hometoastnotification-close" onClick={onClose}>
        <FaTimes />
      </button>
    </div>
  );
};

export default HomeToastNotification;