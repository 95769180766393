import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaClipboardList, FaCheckCircle, FaUsers, FaUser } from 'react-icons/fa';
import './style.css';

const BottomNavigation = () => {
  const location = useLocation();
  
  return (
    <nav className="bottomnavigation-container">
      <Link
        to="/home"
        className={`bottomnavigation-item ${location.pathname === '/home' ? 'bottomnavigation-active' : ''}`}
      >
        <div className="bottomnavigation-icon">
          <FaHome />
        </div>
        <span className="bottomnavigation-label">Home</span>
      </Link>
      <Link
        to="/order"
        className={`bottomnavigation-item ${location.pathname === '/order' ? 'bottomnavigation-active' : ''}`}
      >
        <div className="bottomnavigation-icon">
          <FaClipboardList />
        </div>
        <span className="bottomnavigation-label">Order</span>
      </Link>
      <Link
        to="/proof"
        className={`bottomnavigation-item ${location.pathname === '/proof' ? 'bottomnavigation-active' : ''}`}
      >
        <div className="bottomnavigation-icon">
          <FaCheckCircle />
        </div>
        <span className="bottomnavigation-label">Proof</span>
      </Link>
      <Link
        to="/team"
        className={`bottomnavigation-item ${location.pathname === '/team' ? 'bottomnavigation-active' : ''}`}
      >
        <div className="bottomnavigation-icon">
          <FaUsers />
        </div>
        <span className="bottomnavigation-label">Team</span>
      </Link>
      <Link
        to="/me"
        className={`bottomnavigation-item ${location.pathname === '/me' ? 'bottomnavigation-active' : ''}`}
      >
        <div className="bottomnavigation-icon">
          <FaUser />
        </div>
        <span className="bottomnavigation-label">Me</span>
      </Link>
    </nav>
  );
};

export default BottomNavigation;