import React, { useState } from 'react';
import Loading from './loaders/Loading';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Message from './loaders/Message';
import { useNavigate } from 'react-router-dom';

import './auth.css';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    const token = new URLSearchParams(location.search).get('token');
    setLoading(true);
    try {
      const response = await fetch(`https://api.vanguardafriholdings.com/register/reset-password?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword: password }),
      });

      if (response.ok) {
        setMessage('Password has been reset successfully! Please Login.');
        setMessageType('success');
        setPassword('');
        setConfirmPassword('');
      } else {
        setMessage('Failed to reset password');
        setMessageType('error');
      }
    } catch (error) {
      setMessage('Error occurred while resetting password');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="auth-container">
       {loading && <Loading />}
      <div className="reset-password-card">
        <h2 className="reset-password-title">Reset Password</h2>
        {message && <Message type={messageType} message={message} />}
        <form onSubmit={handleSubmit}>
          <div className="reset-password-input-group">
            <label htmlFor="password" className="input-label">New Password</label>
            <input
             type="password"
             id="password"
             className="reset-password-input-field"
             value={password}
             onChange={(e) => setPassword(e.target.value)}
             required
            />
          </div>
          <div className="reset-password-input-group">
            <label htmlFor="confirmPassword" className="reset-password-input-label">Confirm Password</label>
            <input
              type="password"
              className="reset-password-input-field"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="reset-password-submit-button">Reset Password</button>
        </form>
        <button type="button" className="signup-button" onClick={() => navigate('/login')}>
              Login
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ResetPassword;
