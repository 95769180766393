import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const EmailVerified = () => {

  const navigate = useNavigate();

  return (
    <div className="verificationstatus-container">
      <div className="verificationstatus-box">
        <div className="verificationstatus-content">
          <div className="verificationstatus-icon-wrapper verificationstatus-success">
            <CheckCircle className="verificationstatus-icon" />
          </div>
          
          <h1 className="verificationstatus-heading">
            Email Successfully Verified!
          </h1>
          
          <p className="verificationstatus-message">
            Thank you for verifying your email address. Your account is now fully activated and you can access all features of Vanguard Apple Holdings.
          </p>
          
          <div className="verificationstatus-actions">
          <button 
            className="return-home"
            onClick={() => navigate('/login')}
          >
            Return to Login
          </button>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

const EmailVerificationFailed = () => {
  const [isResending, setIsResending] = useState(false);
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    setToken(tokenParam || '');
  }, []);

  const handleResendVerification = async () => {
    setIsResending(true);
    setMessage('');
    
    try {
      const response = await axios.post(`https://api.vanguardafriholdings.com/register/resend-verification-token?token=${token}`);
      setMessage('A new verification link has been sent to your email.');
    } catch (error) {
      setMessage('Failed to send verification link. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="verificationstatus-container">
      <div className="verificationstatus-box">
        <div className="verificationstatus-content">
          <div className="verificationstatus-icon-wrapper verificationstatus-failure">
            <svg
              className="verificationstatus-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          
          <h1 className="verificationstatus-heading">
            Verification Failed
          </h1>
          
          <p className="verificationstatus-message">
            The verification link you followed has expired or is invalid. Please request a new verification link to complete your registration.
          </p>
          
          {message && (
            <div className={`verificationstatus-alert ${
              message.includes('Failed') 
                ? 'verificationstatus-alert-error'
                : 'verificationstatus-alert-success'
            }`}>
              {message}
            </div>
          )}
          
          <div className="verificationstatus-actions">
            <button
              onClick={handleResendVerification}
              disabled={isResending}
              className={`verificationstatus-button ${
                isResending ? 'verificationstatus-button-disabled' : ''
              }`}
            >
              {isResending ? 'Sending...' : 'Resend Verification Link'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EmailVerified, EmailVerificationFailed };
