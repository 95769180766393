import AppRoutes from "./routes/AppRoutes";
function App() {
  return (
    <div >
    <AppRoutes /> 
    </div>
  );
}

export default App;
