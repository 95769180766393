import React, { useState, useEffect } from 'react';
import { FaUser, FaSpinner, FaExclamationCircle } from 'react-icons/fa';
import axios from 'axios';
import { format } from 'date-fns';
import './style.css';

const DepositRecord = ({ userDetails, token }) => {
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const phoneNumber = userDetails.mobile;
        const response = await axios.get(
          `https://api.vanguardafriholdings.com/api/deposits/${phoneNumber}`,
          { headers: { 'Authorization': token } }
        );
        setDeposits(response.data);
      } catch (err) {
        setError('Failed to load deposit records');
        console.error('Error fetching deposits:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeposits();
  }, [userDetails.mobile, token]);

  if (isLoading) {
    return (
      <div className="depositrecords-loader">
        <FaSpinner className="depositrecords-spinner" />
        <p className="depositrecords-loader-text">Loading records...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="depositrecords-error">
        <FaExclamationCircle className="depositrecords-error-icon" />
        <p className="depositrecords-error-text">{error}</p>
      </div>
    );
  }

  return (
    <div className="depositrecords-container">
      <div className="depositrecords-header">
        <FaUser className="depositrecords-header-icon" />
        <h2 className="depositrecords-title">Deposit Records</h2>
      </div>
      
      <div className="depositrecords-content">
        {deposits.length === 0 ? (
          <div className="depositrecords-empty">
            <p className="depositrecords-empty-text">No deposit records found</p>
          </div>
        ) : (
          deposits.map((deposit) => (
            <div key={deposit.id} className="depositrecords-card">
              <div className="depositrecords-card-main">
                <div className="depositrecords-amount-container">
                  <span className="depositrecords-currency">KES</span>
                  <span className="depositrecords-amount">
                    {deposit.amount.toLocaleString()}
                  </span>
                </div>
                <div className="depositrecords-status-badge" data-status={deposit.status.toLowerCase()}>
                  {deposit.status}
                </div>
              </div>
              
              <div className="depositrecords-card-details">
                <p className="depositrecords-date">
                  {format(new Date(deposit.transactionDate), 'PPp')}
                </p>
                <p className="depositrecords-receipt">
                  Receipt: {deposit.mpesaReceiptNumber}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DepositRecord;