import React, { useEffect, useState } from 'react';

const HotProductSection = ({ product, onInvest }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!product) return null;

  return (
    <div className={`HotProductSection-container ${isVisible ? 'visible' : ''}`}>
      <div className="animated-background"></div>
      <div className="HotProductSection-content">
        <div className="HotProductSection-mainContent">
          <div className="HotProductSection-header">
            <span className="HotProductSection-hotBadge">
              <span className="fire-emoji">🔥</span> HOT
            </span>
            <h2 className="HotProductSection-title">{product.name}</h2>
          </div>

          <div className="HotProductSection-statsGrid">
            <div className="HotProductSection-statBox w-full">
              <div className="stat-card w-full md:max-w-lg">
                <p className="HotProductSection-statLabel">Daily Income: <strong>KES {product.dailyIncome}</strong></p>
                <p className="HotProductSection-statLabel">Total Income: <strong>KES {product.totalIncome}</strong></p>
                <p className="HotProductSection-statLabel">Price: <strong>KES {product.price}</strong></p>
              </div>
            </div>
          </div>

          <div className="HotProductSection-actionArea">
            <button
              onClick={() => onInvest(product)}
              className="HotProductSection-investButton"
            >
              <span className="button-text">INVEST NOW</span>
              <span className="button-shine"></span>
            </button>
          </div>
        </div>

        <div className="HotProductSection-imageContainer">
          <div className="image-wrapper">
            <img 
              src={`https://api.vanguardafriholdings.com${product.imageUrl}`}
              alt={product.name}
              className="HotProductSection-image"
            />
          </div>
        </div>
      </div>

      <div className="HotProductSection-terms">
        <p className="HotProductSection-termsTitle">⚠️ Special Terms:</p>
        <p className="HotProductSection-termsText">
          This is a premium investment that can only be received at the end of its {product.cycle}-day cycle.
        </p>
      </div>
    </div>
  );
};

export default HotProductSection;