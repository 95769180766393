import axios from 'axios';

export const apiClient = axios.create({
    baseURL: 'https://api.vanguardafriholdings.com/api/v1/auth',
});

const API_BASE_URL = 'https://api.vanguardafriholdings.com/api/admin';


const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const checkUserRole = async () => {
    try {
        const response = await axiosInstance.get('/check-role');
        console.log('Check role response:', response.data);
        return response.data.role;
    } catch (error) {
        console.error('Error checking user role:', error.response?.data || error.message);
        throw error;
    }
};


export const checkUserStatus = async () => {
    try {
        const response = await axiosInstance.get('/check-status');
        return response.data.status;
    } catch (error) {
        console.error('Error checking user status:', error.response?.data || error.message);
        throw error;
    }
};