import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Message from './loaders/Message';
import RegisterLoading from './loaders/RegisterLoading';
import './auth.css';

const SignUp = () => {
  const [form, setForm] = useState({
    fullName: '',
    email: '',
    mobile: '',
    password: '',
    confirmPassword: '',
    role: 'USER'
  });


    const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();
  const [invitationCode, setInvitationCode] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Add Google Tag tracking script
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-EYB77V4G2N";
    document.head.appendChild(script);

    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-EYB77V4G2N');
    `;
    document.head.appendChild(gtagScript);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('invite_code');
    if (code) {
      setInvitationCode(code);
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    const errors = {};
    if (!form.fullName.trim()) errors.fullName = 'Full Name is required';
    if (!form.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!form.mobile) {
      errors.mobile = 'Mobile Number is required';
    }
    if (!form.password) {
      errors.password = 'Password is required';
    } else if (form.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }
    if (!form.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (form.confirmPassword !== form.password) {
      errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
  };

  const trackSignupConversion = () => {
    // Track signup event in Google Analytics
    if (window.gtag) {
      window.gtag('event', 'conversion_event_signup', {
        'event_category': 'User',
        'event_label': 'Registration',
        'value': 1
      });

      // Track specific conversion for Google Ads
      window.gtag('event', 'conversion', {
        'send_to': 'AW-306546856/rGbQCKmHjtQZEKiRlpIB',
        'event_callback': () => {
          console.log('Conversion tracked successfully');
        }
      });

      // Push to dataLayer for GTM
      window.dataLayer.push({
        'event': 'signup_complete',
        'user_type': invitationCode ? 'invited' : 'organic',
        'signup_method': 'email'
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    setMessage('');
    setMessageType('');

    try {
      const response = await axios.post('https://api.vanguardafriholdings.com/register', form, {
        params: { invitationCode },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Track successful signup
        trackSignupConversion();
        
        setMessage('Registration successful! Please verify your email.');
        setMessageType('success');
        navigate('/verify-email', { state: { email: form.email } });
      }
    } catch (error) {
      if (error.response?.data?.error) {
        setMessage(error.response.data.error);
      } else {
        setMessage('An error occurred. Please try again.');
      }
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* GTM noscript iframe */}
      <noscript>
        <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-T6TXCVCW"
          height="0" 
          width="0" 
          style={{ display: 'none', visibility: 'hidden' }}
          title="Google Tag Manager"
        />
      </noscript>

      <div className="container">
        <h1 className="app-title">VANGUARD APPLE HOLDINGS</h1>
        {loading && <RegisterLoading />}
        <div className="card">
          <div className="card-content">
            <h2 className="signup-prompt">Create Account</h2>
            {message && <Message type={messageType} message={message} />}
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Full Name"
                  value={form.fullName}
                  onChange={handleChange}
                />
                {errors.fullName && <p className="error">{errors.fullName}</p>}
              </div>
              <div className="input-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  value={form.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="input-group">
                <label htmlFor="mobile">Phone Number</label>
                <input
                  type="number"
                  id="mobile"
                  name="mobile"
                  placeholder="Phone Number"
                  value={form.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && <p className="error">{errors.mobile}</p>}
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={form.password}
                  onChange={handleChange}
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="input-group">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={form.confirmPassword}
                  onChange={handleChange}
                />
                {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
              </div>
              <div className="input-group">
                <label htmlFor="invitationCode">Invitation Code (Optional)</label>
                <input
                  type="text"
                  id="invitationCode"
                  name="invitationCode"
                  placeholder="Invitation Code"
                  value={invitationCode}
                  onChange={(e) => setInvitationCode(e.target.value)}
                />
              </div>
              <button type="submit" className="signup-button">Sign Up</button>
              <p className="login-link">
                Already have an account? <a href="/login">Log in</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
