import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../security/security/AuthContext';
import { checkUserRole } from '../security/api/ApiClient';
import Message from '../userDashboard/auth/loaders/Message';
import Loading from '../userDashboard/auth/loaders/Loading';
import './admin.css';

const AdminLogin = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();
  const authContext = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    const errors = {};
    if (!form.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!form.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        const result = await authContext.login(form.email, form.password);
        if (result.success) {
          try {
            const role = await checkUserRole();
            console.log('User role:', role);
            if (role === 'ADMIN') {
              setMessage('Login successful! Redirecting to admin dashboard...');
              setMessageType('success');
              setTimeout(() => {
                navigate('/admin/dashboard');
              }, 2000);
            } else {
              setMessage('You do not have admin privileges.');
              setMessageType('error');
            }
          } catch (roleError) {
            console.error('Error checking user role:', roleError);
            setMessage('Error verifying admin privileges. Please try again.');
            setMessageType('error');
          }
        } else {
          setMessage(result.message);
          setMessageType('error');
        }
      } catch (error) {
        console.error('Login error:', error);
        setMessage('An unexpected error occurred. Please try again.');
        setMessageType('error');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-card">
        <h1 className="admin-login-title">Admin Login</h1>
        {message && <Message type={messageType} message={message} />}
        <form className="admin-login-form" onSubmit={handleSubmit}>
          <div className="admin-login-form-group">
            <label htmlFor="email" className="admin-login-label">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              className="admin-login-input"
              value={form.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="admin-login-error">{errors.email}</p>}
          </div>
          <div className="admin-login-form-group">
            <label htmlFor="password" className="admin-login-label">Password</label>
            <div className="admin-login-password-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                className="admin-login-input"
                value={form.password}
                onChange={handleChange}
                required
              />
              <span
                className="admin-login-toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </span>
            </div>
            {errors.password && <p className="admin-login-error">{errors.password}</p>}
          </div>
          <button type="submit" className="admin-login-button" disabled={loading}>
            {loading ? <Loading /> : 'Login'}
          </button>
        </form>
        <p className="admin-login-forgot-password">
          Forgot your password? Contact the system administrator.
        </p>
      </div>
    </div>
  );
};

export default AdminLogin;