import React, { useState } from 'react';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import HomeToastNotification from './auth/loaders/HomeToastNotification';
import './style.css';

const ChangePassword = ({ token }) => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showPasswords, setShowPasswords] = useState({
    old: false,
    new: false,
    confirm: false
  });
  const [toastNotification, setToastNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setToastNotification(null);
  };

  const validateForm = () => {
    if (!formData.oldPassword || !formData.newPassword || !formData.confirmPassword) {
      setToastNotification({ message: 'All fields are required', type: 'error' });
      return false;
    }
    if (formData.newPassword !== formData.confirmPassword) {
      setToastNotification({ message: 'New passwords do not match', type: 'error' });
      return false;
    }
    if (formData.newPassword.length < 6) {
      setToastNotification({ message: 'New password must be at least 6 characters long', type: 'error' });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await fetch('https://api.vanguardafriholdings.com/register/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to change password');
      }

      setToastNotification({ message: 'Password changed successfully', type: 'success' });
      setFormData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (err) {
      setToastNotification({ message: err.message || 'An error occurred while changing password', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className="changepassword-container">
      <div className="changepassword-card">
        <div className="changepassword-header">
          <div className="changepassword-icon-wrapper">
            <FaLock className="changepassword-icon" />
          </div>
          <h2 className="changepassword-title">Change Password</h2>
        </div>

        <form onSubmit={handleSubmit} className="changepassword-form">
          {Object.entries({
            old: 'Old Password',
            new: 'New Password',
            confirm: 'Confirm Password'
          }).map(([key, label]) => (
            <div key={key} className="changepassword-input-group">
              <div className="changepassword-input-wrapper">
                <input
                  type={showPasswords[key] ? 'text' : 'password'}
                  name={`${key}Password`}
                  value={formData[`${key}Password`]}
                  onChange={handleChange}
                  placeholder={label}
                  className="changepassword-input"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility(key)}
                  className="changepassword-visibility-toggle"
                >
                  {showPasswords[key] ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          ))}

          <button
            type="submit"
            disabled={isLoading}
            className={`changepassword-submit ${isLoading ? 'changepassword-submit--loading' : ''}`}
          >
            {isLoading ? 'Changing Password...' : 'Change Password'}
          </button>
        </form>

        {toastNotification && (
          <HomeToastNotification
            message={toastNotification.message}
            type={toastNotification.type}
            onClose={() => setToastNotification(null)}
          />
        )}
      </div>
    </div>
  );
};

export default ChangePassword;