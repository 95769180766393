import React, { useState, useEffect } from 'react';
import { FaUser, FaSpinner, FaExclamationCircle } from 'react-icons/fa';
import axios from 'axios';
import { format } from 'date-fns';
import './style.css';

const WithdrawalRecord = ({ userDetails, token }) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const response = await axios.get(
          `https://api.vanguardafriholdings.com/api/withdrawals/history/${userDetails.email}`,
          { headers: { 'Authorization': token } }
        );
        setWithdrawals(response.data);
      } catch (err) {
        setError('Failed to load withdrawal records');
        console.error('Error fetching withdrawals:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWithdrawals();
  }, [userDetails.email, token]);

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case 'APPROVED':
        return 'status-approved';
      case 'PENDING':
        return 'status-pending';
      case 'REJECTED':
        return 'status-rejected';
      default:
        return '';
    }
  };

  const getStatusMessage = (status) => {
    switch (status.toUpperCase()) {
      case 'APPROVED':
        return 'Congratulations! Withdrawal successful';
      case 'PENDING':
        return 'Withdrawal request is being processed';
      case 'REJECTED':
        return 'Withdrawal request was declined';
      default:
        return '';
    }
  };

  if (isLoading) {
    return (
      <div className="withdrawalrecords-loader">
        <FaSpinner className="withdrawalrecords-spinner" />
        <p className="withdrawalrecords-loader-text">Loading records...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="withdrawalrecords-error">
        <FaExclamationCircle className="withdrawalrecords-error-icon" />
        <p className="withdrawalrecords-error-text">{error}</p>
      </div>
    );
  }

  return (
    <div className="withdrawalrecords-container">
      <div className="withdrawalrecords-header">
        <FaUser className="withdrawalrecords-header-icon" />
        <h2 className="withdrawalrecords-title">Withdrawal Records</h2>
      </div>
      
      <div className="withdrawalrecords-content">
        {withdrawals.length === 0 ? (
          <div className="withdrawalrecords-empty">
            <p className="withdrawalrecords-empty-text">No withdrawal records found</p>
          </div>
        ) : (
          withdrawals.map((withdrawal) => (
            <div key={withdrawal.id} className="withdrawalrecords-card">
              <div className="withdrawalrecords-card-main">
                <div className="withdrawalrecords-amount-container">
                  <span className="withdrawalrecords-currency">KES</span>
                  <span className="withdrawalrecords-amount">
                    {withdrawal.requestedAmount.toLocaleString()}
                  </span>
                </div>
                <div className={`withdrawalrecords-status-badge ${getStatusColor(withdrawal.withdrawalStatus)}`}>
                  {withdrawal.withdrawalStatus}
                </div>
              </div>
              
              <div className="withdrawalrecords-card-details">
                <p className="withdrawalrecords-date">
                  {format(new Date(withdrawal.requestTime), 'PPp')}
                </p>
                <p className="withdrawalrecords-order">
                  Order: {withdrawal.orderNumber}
                </p>
                <p className="withdrawalrecords-receive">
                  Amount to receive: KES {withdrawal.amountToReceive.toLocaleString()}
                </p>
                <p className={`withdrawalrecords-message ${getStatusColor(withdrawal.withdrawalStatus)}`}>
                  {getStatusMessage(withdrawal.withdrawalStatus)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default WithdrawalRecord;