import React, { useState } from 'react';
import './auth.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from './loaders/Loading';
import Message from './loaders/Message';

  const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://api.vanguardafriholdings.com/register/password-reset-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Password reset link sent! Check your email.');
        setMessageType('success');
        setEmail('');
      } else {
        setMessage('Failed to send password reset link.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage('Error occurred. Please try again.');
      setMessageType('error');

    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="auth-container">
      {loading && <Loading />}
      <div className="auth-wrapper">
        <h2>Reset Your Password</h2>
        {message && <Message type={messageType} message={message} />}
        <p >
          Enter your email address below and we'll send you instructions to reset your password.
        </p>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Email Address"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <button type="submit" className="btn-auth">Send Reset Link</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
