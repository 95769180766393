// RegisterLoading.jsx
import React from 'react';

const RegisterLoading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Creating your account...</p>
      </div>
    </div>
  );
};

export default RegisterLoading;