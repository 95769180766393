import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import BottomNavigation from './BottomNavigation';
import './style.css';
import axios from 'axios';
import { useAuth } from '../security/security/AuthContext';
import HomeToastNotification from './auth/loaders/HomeToastNotification';
import Loading from './auth/loaders/Loading';

const OrderItem = ({ order, onReceive }) => (
  <div className="order-item">
    <h2>{order.productName}</h2>
    <p>Valid until: {new Date(order.validUntil).toLocaleString()}</p>
    <p>Average hourly profit: KES {(order.dailyIncome / 24).toFixed(2)}</p>
    <p>Total income: KES {order.totalIncome}</p>
    <p>Purchased price: KES {order.investedAmount}</p>
    <div className="order-footer">
      <span className="earned-amount">+KES {order.earnedAmount.toFixed(2)}</span>
      {order.isActive && (
        <button 
          className="receive-btn" 
          onClick={() => onReceive(order.id, order.productType)} 
          disabled={!order.readyToReceive || order.earnedAmount <= 0}
        >
          {!order.readyToReceive && order.productType === 'HOT' 
            ? 'Available at end of cycle' 
            : 'Receive'}
        </button>
      )}
    </div>
  </div>
);

const LoadingState = () => (
  <div className="loading-container">
    <Loading />
    <p>Loading investments...</p>
  </div>
);

const Order = () => {
  const [activeTab, setActiveTab] = useState('normal');
  const [investments, setInvestments] = useState([]);
  const { userDetails, token, updateUserDetails } = useAuth();
  const [toastNotification, setToastNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const refreshUserData = async () => {
    try {
      const userResponse = await axios.get(`https://api.vanguardafriholdings.com/api/v1/auth/${userDetails.email}`, {
        headers: {
          'Authorization': token
        }
      });
      updateUserDetails(userResponse.data);
    } catch (error) {
      console.error('Error refreshing user data:', error);
      setToastNotification({ message: 'Failed to refresh user data', type: 'error' });
    }
  };

  const refreshAllData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        refreshUserData(),
        fetchInvestments()
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshAllData();
  }, []);

  const fetchInvestments = async () => {
    try {
      const response = await axios.get(`https://api.vanguardafriholdings.com/api/investments/user/${userDetails.email}`);
      // Sort investments by ID in descending order (higher IDs first)
      const sortedInvestments = response.data.sort((a, b) => b.id - a.id);
      setInvestments(sortedInvestments);
    } catch (error) {
      console.error('Error fetching investments:', error);
      setToastNotification({ 
        message: 'An error occurred while fetching investments. Please try again.', 
        type: 'error' 
      });
    }
  };

  const handleReceive = async (investmentId, productType) => {
    try {
      await axios.post(`https://api.vanguardafriholdings.com/api/investments/${investmentId}/receive`);
      await refreshAllData();
      setToastNotification({ 
        message: 'Income received successfully!', 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error receiving income:', error);
      let errorMessage = 'An error occurred while receiving income. Please try again.';
      
      // Handle specific error messages from the backend
      if (error.response && error.response.data) {
        errorMessage = error.response.data;
      }
      
      setToastNotification({ 
        message: errorMessage, 
        type: 'error' 
      });
    }
  };

  const renderInvestments = () => {
    if (isLoading) {
      return <LoadingState />;
    }

    const filteredInvestments = investments
      .filter(inv => activeTab === 'normal' ? inv.active : !inv.active);

    if (filteredInvestments.length === 0) {
      return (
        <div className="text-center p-4">
          <p className="text-gray-500">
            {activeTab === 'normal' 
              ? "You don't have any active investments yet." 
              : "You don't have any expired investments."}
          </p>
        </div>
      );
    }

    return filteredInvestments.map(inv => (
      <OrderItem 
        key={inv.id} 
        order={{
          ...inv,
          isActive: inv.active,
          validUntil: new Date(inv.validUntil),
        }}
        onReceive={handleReceive}
      />
    ));
  };

  return (
    <div className="order-container">
      <div className="order-header">
        <button className="back-btn" onClick={() => window.history.back()}>
          <FaArrowLeft /> Back
        </button>
        <h1 className="order-title">Income</h1>
      </div>
      <div className="order-tabs">
        <button 
          className={`tab-btn ${activeTab === 'normal' ? 'active' : ''}`}
          onClick={() => setActiveTab('normal')}
        >
          Normal
        </button>
        <button 
          className={`tab-btn ${activeTab === 'expired' ? 'active' : ''}`}
          onClick={() => setActiveTab('expired')}
        >
          Expired
        </button>
      </div>
      <div className="order-list">
        {renderInvestments()}
      </div>
      <BottomNavigation />
      {toastNotification && (
        <HomeToastNotification
          message={toastNotification.message}
          type={toastNotification.type}
          onClose={() => setToastNotification(null)}
        />
      )}
    </div>
  );
};

export default Order;