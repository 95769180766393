import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa';
import { Check } from 'lucide-react';
import BottomNavigation from './BottomNavigation';
import { useAuth } from '../security/security/AuthContext';
import axios from 'axios'

import { FaGift } from 'react-icons/fa';
import { AlertCircle } from 'lucide-react';
import HomeToastNotification from './auth/loaders/HomeToastNotification';


const Toast = ({ message, isVisible, onHide }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        onHide();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onHide]);

  if (!isVisible) return null;

  return (
    <div className="toast-notification">
      <div className="toast-icon-wrapper">
        <Check size={16} />
      </div>
      <span className="toast-message">{message}</span>
    </div>
  );
};


const Team = () => {
  const { userDetails, token, updateUserDetails } = useAuth();
  const [referralStats, setReferralStats] = useState({ totalReferrals: 0, totalRevenue: 0 });
  const [inviteLink, setInviteLink] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [rewardCode, setRewardCode] = useState('');
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimError, setClaimError] = useState('');
  const [lastClaimDate, setLastClaimDate] = useState(null);
  const [toastNotification, setToastNotification] = useState(null);

  const handleClaimReward = async () => {
    try {
      setClaimLoading(true);
      setClaimError('');
      
      const response = await axios.post(
        'https://api.vanguardafriholdings.com/api/rewards/claim',
        { rewardCode },
        {
          headers: { 'Authorization': token }
        }
      );

      if (response.data.success) {
        setToastNotification({ message: 'Reward claimed successfully!', type: 'success' });
        setRewardCode('');
        refreshUserData();
        setLastClaimDate(new Date().toISOString());
      }
    } catch (error) {
      setClaimError(error.response?.data?.error || 'Failed to claim reward');
    } finally {
      setClaimLoading(false);
    }
  };
  

  // Add refreshUserData function
  const refreshUserData = async () => {
    try {
      const userResponse = await axios.get(`https://api.vanguardafriholdings.com/api/v1/auth/${userDetails.email}`, {
        headers: {
          'Authorization': token
        }
      });
      updateUserDetails(userResponse.data);
    } catch (error) {
      console.error('Error refreshing user data:', error);
      // Handle error silently as Toast is used for other purposes in this component
    }
  };

  

  useEffect(() => {
    // Refresh user data when component mounts
    refreshUserData();
    
    setInviteLink(`https://vanguardafriholdings.com/signup?invite_code=${userDetails.invitationCode}`);
  }, [userDetails]);

  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    setShowToast(true);
  };

  const shareViaWhatsApp = () => {
    const encodedMessage = encodeURIComponent(`Join Vanguard Apple Holdings using my invitation link: ${inviteLink}`);
    window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
  };

  const shareViaTelegram = () => {
    const encodedMessage = encodeURIComponent(`Join Vanguard Apple Holdings using my invitation link: ${inviteLink}`);
    window.open(`https://t.me/share/url?url=${encodedMessage}`, '_blank');
  };

  return (
    <div className="team-container">
      <h1 className="team-title">My Team</h1>
      
      <div className="stats-card">
        <div className="stat-item">
          <h2>{userDetails.totalReferrals}</h2>
          <p>Total referrals</p>
        </div>
        <div className="stat-item">
          <h2>KES {userDetails.invitationIncome}</h2>
          <p>Total revenue</p>
        </div>
      </div>

      <div className="team-illustration">
        <img src="/team_illustration.webp" alt="Team Illustration" />
      </div>

      <div className="invite-link-card" onClick={copyToClipboard}>
        <p>Invitation link (click to copy)</p>
        <div className="invite-link">
          {inviteLink}
        </div>
      </div>

      <h3 className="share-title">Share via</h3>
      <div className="share-buttons">
        <button className="share-btn whatsapp" onClick={shareViaWhatsApp}>
          <FaWhatsapp /> Whatsapp
        </button>
        <button className="share-btn telegram" onClick={shareViaTelegram}>
          <FaTelegram /> Telegram
        </button>
      </div>

      <div className="daily-reward-card">
        <h3 className="reward-title">
          <FaGift className="reward-icon" />
          Daily Reward
        </h3>
        <p className="reward-description">
          Enter today's reward code to claim your daily bonus!
        </p>
        
        <div className="reward-input-group">
          <input
            type="text"
            placeholder="Enter reward code"
            value={rewardCode}
            onChange={(e) => setRewardCode(e.target.value.toUpperCase())}
            maxLength={6}
            className="reward-input"
          />
          
        </div>
        <button
            className="claim-btn"
            onClick={handleClaimReward}
            disabled={claimLoading || !rewardCode}
          >
            {claimLoading ? 'Claiming...' : 'Claim Reward'}
          </button>
        
        {claimError && (
          <div className="claim-error">
            <AlertCircle size={16} />
            <span>{claimError}</span>
          </div>
        )}
        
        {lastClaimDate && (
          <p className="last-claim">
            Last claimed: {new Date(lastClaimDate).toLocaleDateString()}
          </p>
        )}
      </div>

      <Toast 
        message="Link copied to clipboard!" 
        isVisible={showToast} 
        onHide={() => setShowToast(false)} 
      />
      
      <BottomNavigation />
      {toastNotification && (
        <HomeToastNotification
          message={toastNotification.message}
          type={toastNotification.type}
          onClose={() => setToastNotification(null)}
        />
      )}
    </div>
  );
};

export default Team;