import React, { useState, useEffect } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './landing.css';


// Google Tag Manager
const addGoogleTagManager = () => {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-EYB77V4G2N";
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-EYB77V4G2N');
  `;
  document.head.appendChild(script2);

  const gtmScript = document.createElement('script');
  gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MTX5VFJ3');
  `;
  document.head.appendChild(gtmScript);

  const newGtmScript = document.createElement('script');
  newGtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MC67J4SL');
  `;
  document.head.appendChild(newGtmScript);

  // Google Tag Manager (noscript)
  const noScript = document.createElement('noscript');
  noScript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC67J4SL"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.insertBefore(noScript, document.body.firstChild);
};


const investmentData = [
  { year: '2018', value: 750 },
  { year: '2019', value: 820 },
  { year: '2020', value: 880 },
  { year: '2021', value: 930 },
  { year: '2022', value: 977.3 }
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    addGoogleTagManager();

    const handleScroll = () => {
      setScrolled(window.scrollY > 50);

      const sections = ['hero', 'features', 'stats', 'vision'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const bounds = element.getBoundingClientRect();
          return bounds.top <= 100 && bounds.bottom >= 100;
        }
        return false;
      });

      if (current) {
        setActiveSection(current);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleStartInvesting = () => {
    navigate('/signup');
  };

  const whatsappservice = () => {
    const whatsappLink = "https://wa.link/wzy3qh";
    window.open(whatsappLink, '_blank');
  };

  const handleLearnMore = () => {
    setShowLearnMore(true);
  };

  
  return (
    <div className="landingpage-container">
    {/* Google Tag Manager (noscript) */}
    <noscript>
        <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-MTX5VFJ3"
          height="0" 
          width="0" 
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
      {/* Google Tag Manager (noscript) */}
    <noscript>
            <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-MC67J4SL"
          height="0" 
          width="0" 
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <div className={`cursor-follow ${scrolled ? 'cursor-scrolled' : ''}`} />
      
      <nav className={`landingpage-nav ${scrolled ? 'landingpage-nav-scrolled' : ''}`}>
        <div className="landingpage-nav-content">
          <div className="landingpage-logo">
            <motion.span 
              className="landingpage-logo-icon"
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            >
              ◆
            </motion.span>
            <span className="landingpage-logo-text">Vanguard</span>
          </div>
          
          <button 
            className="landingpage-mobile-menu-button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>

          <div className={`landingpage-nav-links ${isMenuOpen ? 'open' : ''}`}>
            <a href="#features" className={activeSection === 'features' ? 'active' : ''}>Features</a>
            <a href="#stats" className={activeSection === 'stats' ? 'active' : ''}>Stats</a>
            <a href="#vision" className={activeSection === 'vision' ? 'active' : ''}>Vision</a>
            <button className="landingpage-nav-login" onClick={() => navigate('/login')}>Login</button>
            <button className="landingpage-nav-cta" onClick={handleStartInvesting}>Get Started</button>
            <button className="service-btn whatsapp" onClick={whatsappservice}>24/7 Helpline</button>
          </div>
        </div>
      </nav>

      <section id="hero" className="landingpage-hero">
      <div className="tech-grid"></div>
        <div className="floating-elements">
          <div 
            className="tech-particle" 
            style={{ 
              top: '20%', 
              left: '30%' 
            }}
          ></div>
          <div 
            className="tech-particle" 
            style={{ 
              top: '60%', 
              left: '70%' 
            }}
          ></div>
          <div 
            className="tech-particle" 
            style={{ 
              top: '40%', 
              left: '50%' 
            }}
          ></div>
        </div>
        <div className="landingpage-hero-content">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1>
              <span className="landingpage-hero-subtitle">Welcome to the future of</span>
              <span className="landingpage-hero-title">Apple Investment</span>
            </h1>
            <p className="landingpage-hero-description">
              Experience the next generation of technology investment through our innovative platform
            </p>
            <div className="landingpage-hero-cta-group">
              <motion.button 
                className="landingpage-cta-primary"
                onClick={handleStartInvesting}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Start Investing
              </motion.button>
              <motion.button 
                className="landingpage-cta-secondary"
                onClick={handleLearnMore}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
              </motion.button>
            </div>
          </motion.div>
        </div>

                
        <motion.div 
          className="landingpage-hero-visual"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <div className="landingpage-hero-chart">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={investmentData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#2a2a2a" />
                <XAxis dataKey="year" stroke="#999" />
                <YAxis stroke="#999" />
                <Tooltip 
                  contentStyle={{ 
                    background: 'rgba(0,0,0,0.8)', 
                    border: 'none', 
                    borderRadius: '8px',
                    color: '#fff' 
                  }} 
                />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#0066CC" 
                  strokeWidth={3}
                  dot={{ fill: '#0066CC', strokeWidth: 2 }}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </motion.div>
      </section>

      <section id="features" className="landingpage-features">
        <motion.div 
          className="landingpage-section-header"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2>Why Choose Us</h2>
          <p>Discover the advantages of investing with Vanguard Apple Holdings</p>
        </motion.div>
        
        <div className="landingpage-features-grid">
          {[
            {
              icon: "💡",
              title: "Innovation First",
              description: "Access to cutting-edge technology investments before they hit the market"
            },
            {
              icon: "🛡️",
              title: "Secure Platform",
              description: "Enterprise-grade security protecting your investments 24/7"
            },
            {
              icon: "📊",
              title: "Real-time Analytics",
              description: "Advanced analytics and AI-powered insights for informed decisions"
            },
            {
              icon: "🌍",
              title: "Global Reach",
              description: "Access to international markets and diverse investment opportunities"
            }
          ].map((feature, index) => (
            <motion.div
              key={index}
              className="landingpage-feature-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ y: -10, transition: { duration: 0.2 } }}
            >
              <span className="landingpage-feature-icon">{feature.icon}</span>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section id="stats" className="landingpage-stats">
        <div className="landingpage-stats-grid">
          {[
            { value: "$977.3B", label: "Total Investment" },
            { value: "120K+", label: "Active Patents" },
            { value: "55.4%", label: "Growth Rate" },
            { value: "29+", label: "Global Regions" }
          ].map((stat, index) => (
            <motion.div
              key={index}
              className="landingpage-stat-card"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <span className="landingpage-stat-value">{stat.value}</span>
              <span className="landingpage-stat-label">{stat.label}</span>
            </motion.div>
          ))}
        </div>
      </section>

      <section id="vision" className="landingpage-vision">
        <motion.div
          className="landingpage-vision-content"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2>Our Vision for Tomorrow</h2>
          <p>Join us in shaping the future of technology investment. Together, we can create a world where innovation meets opportunity.</p>
          <motion.button 
            className="landingpage-cta-primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleStartInvesting}
          >
            Join the Revolution
          </motion.button>
        </motion.div>
      </section>

      <footer className="landingpage-footer">
        <div className="landingpage-footer-content">
          <div className="landingpage-footer-logo">
            <span className="landingpage-logo-icon">◆</span>
            <span>Vanguard</span>
          </div>
          <p className="landingpage-footer-copyright">
            © 2024 Vanguard Apple Holdings. All rights reserved.
          </p>
        </div>
      </footer>

      {showLearnMore && (
        <div className="learn-more-modal">
          <div className="learn-more-content">
            <button className="close-modal" onClick={() => setShowLearnMore(false)}>×</button>
            <h2>Discover More About Apple Investment</h2>
            <div className="learn-more-grid">
              <div className="learn-more-item">
                <h3>Strategic Investment</h3>
                <p>Learn about our data-driven approach to Apple stock investment.</p>
              </div>
              <div className="learn-more-item">
                <h3>Market Analysis</h3>
                <p>Get insights from our expert market analysts.</p>
              </div>
              <div className="learn-more-item">
                <h3>Investment Plans</h3>
                <p>Explore our flexible investment options.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;