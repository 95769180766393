import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../security/security/AuthContext';
import Message from './loaders/Message';
import LoadingLogin from './loaders/LoadingLogin';
import './auth.css';
import { checkUserStatus } from '../../security/api/ApiClient';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const navigate = useNavigate();
  const authContext = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    const errors = {};
    if (!form.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!form.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      const result = await authContext.login(form.email, form.password);
      if (result.success) {
        // Check user status after successful login
        const userStatus = await checkUserStatus();
        
        switch(userStatus) {
          case 'ACTIVE':
          case 'INACTIVE':
            setMessage('Login successful!');
            setMessageType('success');
            setTimeout(() => {
              navigate('/home');
            }, 2000);
            break;
          case 'SUSPENDED':
            setMessage('Your account has been suspended. Please contact the administrator.');
            setMessageType('error');
            // Clear the auth token since we don't want suspended users to remain logged in
            authContext.logout();
            break;
          default:
            setMessage('Invalid account status. Please contact support.');
            setMessageType('error');
            authContext.logout();
        }
      } else {
        setMessage(result.message);
        setMessageType('error');
      }
    } catch (error) {
      setMessage('An unexpected error occurred');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h1 className="app-title">VANGUARD APPLE HOLDINGS</h1>
      {loading && <LoadingLogin />}
      <div className="card">
        <div className="card-content">
          <h2 className="login-prompt">Login to continue</h2>
          {message && <Message type={messageType} message={message} />}
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                value={form.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={form.password}
                onChange={handleChange}
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <button type="submit" className="login-button">Login</button>
            <button type="button" className="forgot-password-button" onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </button>
            <button type="button" className="signup-button" onClick={() => navigate('/signup')}>
              Don't have an account? Sign Up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;