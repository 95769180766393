import React, { useState, useEffect } from 'react';
import { MdAttachMoney, MdCardGiftcard } from 'react-icons/md';
import { IoMdCash } from 'react-icons/io';
import { FaArrowLeft, FaCheckCircle, FaUser, FaClipboard, FaTimes } from 'react-icons/fa';
import './style.css';
import BottomNavigation from './BottomNavigation';
import LiveTransactionsBot from './LiveTransactionsBot';
import { useAuth } from '../security/security/AuthContext';
import axios from 'axios';
import HomeToastNotification from './auth/loaders/HomeToastNotification';
import CashoutView from './CashoutView';
import HotProductSection from './HotProductSection';
import TaskView from './TaskView';

const Home = () => {
  const [selectedNav, setSelectedNav] = useState('home');
  const [showPopup, setShowPopup] = useState(true);
  const [currentView, setCurrentView] = useState('main');
  const [depositAmount, setDepositAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [openedGiftBoxes, setOpenedGiftBoxes] = useState([]);
  const [showInvestmentDialog, setShowInvestmentDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { userDetails, token, updateUserDetails, isLoading } = useAuth();
  const [products, setProducts] = useState([]);
  const [toastNotification, setToastNotification] = useState(null);
  const [investmentSuccess, setInvestmentSuccess] = useState(null);

  // New states for payment processing
  const [showPaymentLoader, setShowPaymentLoader] = useState(false);
  const [paymentLoaderMessage, setPaymentLoaderMessage] = useState('');
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [transactionCode, setTransactionCode] = useState('');
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [isActivated, setIsActivated] = useState(false);

  const [hotProduct, setHotProduct] = useState(null);



  useEffect(() => {
    if (!isLoading && userDetails?.mobile) {
      setPhoneNumber(userDetails.mobile);
    }
    if (!isLoading && userDetails?.email) {
      fetchAvailableProducts();
    }
  }, [userDetails, isLoading]);

  const formatPhoneNumber = (number) => {
    if (number.startsWith('07') || number.startsWith('01')) {
      return '254' + number.slice(1);
    }
    return number;
  };

  
// Add a new function to refresh user data
const refreshUserData = async () => {
  try {
    const userResponse = await axios.get(`https://api.vanguardafriholdings.com/api/v1/auth/${userDetails.email}`, {
      headers: {
        'Authorization': token
      }
    });
    updateUserDetails(userResponse.data); // Use updateUserDetails instead of setUserDetails
  } catch (error) {
    console.error('Error refreshing user data:', error);
    setToastNotification({ message: 'Failed to refresh user data', type: 'error' });
  }
};


   // Add a function to refresh products
   const refreshProducts = async () => {
    try {
      const response = await axios.get(`https://api.vanguardafriholdings.com/api/products/available/${userDetails.email}`);
      if (response.data && Array.isArray(response.data)) {
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Error refreshing products:', error);
    }
  };


  // Combined refresh function
  const refreshAllData = async () => {
    await Promise.all([
      refreshUserData(),
      refreshProducts()
    ]);
  };

  // Add effect to refresh data when view changes to 'main'
  useEffect(() => {
    if (currentView === 'main') {
      refreshAllData();
    }
  }, [currentView]);


  // Modify the payment success handler
  const handlePaymentSuccess = async () => {
    await refreshAllData();
    setCurrentView('paymentSuccess');
  };




  useEffect(() => {
    fetchAvailableProducts();
  }, [userDetails.email]);

  const fetchAvailableProducts = async () => {
    try {
      const [productsResponse, hotProductsResponse] = await Promise.all([
        axios.get(`https://api.vanguardafriholdings.com/api/products/available/${userDetails.email}`),
        axios.get('https://api.vanguardafriholdings.com/api/products/hot')
      ]);
      
      if (productsResponse.data && Array.isArray(productsResponse.data)) {
        // Filter out HOT products from the regular products list
        setProducts(productsResponse.data.filter(p => p.productType === 'NORMAL'));
      }
      
      if (hotProductsResponse.data && hotProductsResponse.data.length > 0) {
        setHotProduct(hotProductsResponse.data[0]); // Get the first hot product
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setToastNotification({ message: 'Failed to fetch products. Please try again.', type: 'error' });
    }
  };


  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://api.vanguardafriholdings.com/api/products/all');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleInvest = (product) => {
    setSelectedProduct(product);
    setQuantity(1);
    setShowInvestmentDialog(true);
  };

  const handleCloseInvestmentDialog = () => {
    setShowInvestmentDialog(false);
    setSelectedProduct(null);
    setQuantity(1);
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const calculateTotalBalance = () => {
    const wallet = Number(userDetails.wallet) || 0;
    const invitationIncome = Number(userDetails.invitationIncome) || 0;
    return (wallet + invitationIncome).toLocaleString();
  };

   // Update the handleInvestNow function
   const handleInvestNow = async () => {
    try {
      const response = await axios.post('https://api.vanguardafriholdings.com/api/investments/invest', null, {
        params: {
          userEmail: userDetails.email,
          productId: selectedProduct.id,
          quantity: quantity
        }
      });
      
      if (response.status === 200) {
        await refreshAllData(); // Refresh data after successful investment
        setShowInvestmentDialog(false);
        setInvestmentSuccess(response.data);
        setCurrentView('investmentSuccess');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setToastNotification({ message: error.response.data, type: 'error' });
        setCurrentView('deposit');
        setDepositAmount((selectedProduct.price * quantity).toString());
      } else {
        console.error('Error making investment:', error);
        setToastNotification({ message: 'Ooops Sorry. Pleae Try Again!.', type: 'error' });
      }
    }
  };


  const renderInvestmentSuccessView = () => (
    <div className="investment-success-view">
      <div className="success-header">
        <FaCheckCircle className="success-icon" />
        <h2>Investment Successful!</h2>
      </div>
      <div className="investment-details">
        <h3>{investmentSuccess.productName}</h3>
        <p>Quantity: {quantity}</p>
        <p>Invested Amount: KES {investmentSuccess.investedAmount.toLocaleString()}</p>
        <p>Daily Income: KES {investmentSuccess.dailyIncome.toLocaleString()}</p>
        <p>Total Expected Income: KES {investmentSuccess.totalIncome.toLocaleString()}</p>
        <p>Investment Cycle: {investmentSuccess.validUntil.split('T')[0]}</p>
      </div>
      <div className="income-info">
        <h3>How to Receive Income</h3>
        <p>You can receive your investment income every hour on the Order page.</p>
        <p>When the investment cycle is over, your principal will automatically return to your balance.</p>
      </div>
      <button className="view-orders-btn" onClick={() => window.location.href = '/order'}>View Orders</button>
      <button className="back-to-home-btn" onClick={() => setCurrentView('main')}>Back to Home</button>
    </div>
  );


  const downloadApp = () => {
    setToastNotification({ message: 'Mobile App will be available Soon.', type: 'success' });
    // const appDownloadLink = "https://play.google.com/store/apps/your-app-link";
    // window.open(appDownloadLink, '_blank');
  };
  
  const joinTelegramChannel = () => {
    const telegramLink = "https://t.me/+y-cgsiLhbKBiYjk0";
    window.open(telegramLink, '_blank');
  };


  const renderMainView = () => (
    <>
     <div className="home-container">
        <header className="home-header">
          <img src="/apple-logo.png" alt="Logo" className="logo" />
          <h1>VANGUARD APPLE HOLDINGS</h1>
        </header>

        <LiveTransactionsBot />

        <div className="action-buttons">
          <button className="action-btn" onClick={() => setCurrentView('deposit')}><MdAttachMoney /> Deposit</button>
          <button className="action-btn" onClick={() => setCurrentView('cashout')}><IoMdCash /> Cashout</button>
          <button className="action-btn" onClick={() => setCurrentView('task')}><MdCardGiftcard /> Task</button>
        </div>

        <div className="balance-card">
          <h3>Balance</h3>
          <h2>Ksh. {calculateTotalBalance()}</h2>
        </div>
           {hotProduct && <HotProductSection product={hotProduct} onInvest={handleInvest} />}

           {/* Package Area - Only show if there are normal products */}
           {products.length > 0 && (
          <>
            <h2 className="section-title">Package Area</h2>
            <div className="products-list">
              {products.map((product, index) => (
                <div key={index} className="product-card">
                  <img src={`https://api.vanguardafriholdings.com${product.imageUrl}`} alt={product.name} className="product-image" />
                  <div className="product-info">
                    <h3>{product.name}</h3>
                    <p>Cycle(Days): {product.cycle}</p>
                    <p>Available: {product.available}</p>
                  </div>
                  <div className="product-stats">
                    <div>
                      <h4>{product.dailyIncome}</h4>
                      <p>Daily Income(KES)</p>
                    </div>
                    <div>
                      <h4>{product.totalIncome}</h4>
                      <p>Total Income(KES)</p>
                    </div>
                  </div>
                  <div className="product-action">
                    <p>Price(KES) {product.price}</p>
                    <button 
                      className="invest-btn" 
                      onClick={() => handleInvest(product)}
                      disabled={product.available < 1}
                    >
                      INVEST
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {showInvestmentDialog && selectedProduct && (
        <div className="investment-dialog">
          <button className="close-btn" onClick={handleCloseInvestmentDialog}><FaTimes /></button>
          <div className="investment-dialog-content">
            <h2>{selectedProduct.name}</h2>
            <p>Price(KES): {selectedProduct.price}</p>
            <div className="quantity-buttons">
              {[...Array(Math.min(selectedProduct.available, 6))].map((_, index) => (
                <button
                  key={index + 1}
                  className={`quantity-btn ${quantity === index + 1 ? 'selected' : ''}`}
                  onClick={() => handleQuantityChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <div className="investment-details">
              <p>Daily income: KES {selectedProduct.dailyIncome * quantity}</p>
              <p>Total income: KES {selectedProduct.totalIncome * quantity}</p>
              <p>Available: {selectedProduct.available}</p>
            </div>
            <p>Need to pay: KES {selectedProduct.price * quantity}</p>
            <button className="invest-now-btn" onClick={handleInvestNow}>INVEST NOW</button>
            <p className="investment-note">You can receive your investment income every hour. When the investment cycle is over, your principal will automatically return to your balance and can be withdrawn. <strong>Note: Some Investments need you to Recieve before Expiry</strong></p>
          </div>
        </div>
      )}
    </>
  );


 
  const renderCashoutView = () => (
    <CashoutView 
      setCurrentView={setCurrentView}
      userDetails={userDetails}
      token={token}
      calculateTotalBalance={calculateTotalBalance}
      setToastNotification={setToastNotification}
    />
  );



  const renderTaskView = () => (
    <TaskView 
      userEmail={userDetails.email}
      onInvest={(gadget) => {
        setSelectedProduct(gadget);
        setQuantity(gadget.shares);
        setShowInvestmentDialog(true);
        setCurrentView('main');
      }}
    />
  );



  // New payment processing functions
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setShowPaymentLoader(true);
    setPaymentLoaderMessage('Payment initiated, please check your phone to complete payment.');

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const requestData = {
      PhoneNumber: formattedPhoneNumber,
      Amount: parseInt(depositAmount),
    };

    try {
      await axios.post('https://api.vanguardafriholdings.com/mobile-money/stk-transaction-request', requestData, {
        headers: {
          'Authorization': token
        }
      });
      
      setTimeout(() => {
        setPaymentLoaderMessage('Processing payment...');
      }, 20000);

      setTimeout(() => {
        checkPaymentStatus(formattedPhoneNumber);
      }, 27000);
    } catch (error) {
      console.error(error);
      setShowPaymentLoader(false);
      setToastNotification({ message: 'Failed to initiate payment. Please try again.', type: 'error' });
    }
  };


  const checkPaymentStatus = async (phoneNumber) => {
    try {
      const response = await axios.get(`https://api.vanguardafriholdings.com/mobile-money/transactions/check-payment/${phoneNumber}`, {
        headers: {
          'Authorization': token
        }
      });
  
      if (response.data.paymentFound) {
        // Process the transaction with both phone number and email
        await processTransaction(phoneNumber);
        setPaymentSuccess(true);
        setShowPaymentLoader(false);
        setToastNotification({ message: 'Payment successful!', type: 'success' });
        setCurrentView('paymentSuccess');
      } else {
        setShowPaymentLoader(false);
        setShowTransactionForm(true);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setShowPaymentLoader(false);
      setToastNotification({ message: 'Failed to check payment status. Please try again.', type: 'error' });
    }
  };


  // Update the processTransaction function
  const processTransaction = async (phoneNumber) => {
    try {
      const response = await axios.post('https://api.vanguardafriholdings.com/mobile-money/users/activate', {
        phoneNumber: phoneNumber,
        email: userDetails.email
      }, {
        headers: {
          'Authorization': token
        }
      });
  
      if (response.data.message) {
        setIsActivated(true);
        await refreshAllData(); // Refresh data after successful activation
      }
    } catch (error) {
      console.error('Error processing transaction:', error);
      throw error;
    }
  };


  // Update the handleTransactionCodeSubmit function
  const handleTransactionCodeSubmit = async (e) => {
    e.preventDefault();
    setIsSubmittingPayment(true);
  
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  
    try {
      const verifyResponse = await axios.post('https://api.vanguardafriholdings.com/mobile-money/transactions/verify', {
        phoneNumber: formattedPhoneNumber,
        transactionCode: transactionCode
      }, {
        headers: {
          'Authorization': token
        }
      });
  
      if (verifyResponse.data.verified) {
        await processTransaction(formattedPhoneNumber);
        await refreshAllData(); // Refresh data after successful verification
        setPaymentSuccess(true);
        setCurrentView('paymentSuccess');
        setToastNotification({ message: 'Payment verified successfully!', type: 'success' });
      } else {
        setToastNotification({ message: 'Invalid transaction code. Please try again.', type: 'error' });
      }
    } catch (error) {
      console.error('Error verifying transaction:', error);
      setToastNotification({ message: 'Failed to verify transaction. Please try again.', type: 'error' });
    } finally {
      setIsSubmittingPayment(false);
    }
  };



  // New view renderers for payment process
  const renderPaymentProcessView = () => (
    <div className="depositprocess-container">
      <div className="depositprocess-header">
        <button className="depositprocess-back-btn" onClick={() => setCurrentView('deposit')}>
          <FaArrowLeft /> Back
        </button>
        <h2>Complete Payment</h2>
      </div>

      {!showPaymentLoader && !showTransactionForm && (
        <form className="depositprocess-form" onSubmit={handlePaymentSubmit}>
          <div className="depositprocess-form-group">
            <label>Amount (KES)</label>
            <input 
              type="number" 
              value={depositAmount} 
              disabled 
              className="depositprocess-input"
            />
          </div>
          <div className="depositprocess-form-group">
            <label>Phone Number</label>
            <input 
              type="tel" 
              value={phoneNumber} 
              disabled 
              className="depositprocess-input"
            />
          </div>
          <button type="submit" className="depositprocess-submit-btn">
            Pay Now
          </button>
        </form>
      )}

      {showPaymentLoader && (
        <div className="depositprocess-loader">
          <div className="depositprocess-spinner"></div>
          <p className="depositprocess-loader-text">{paymentLoaderMessage}</p>
        </div>
      )}

      {showTransactionForm && (
        <div className="depositprocess-transaction-form">
          <h3>Payment Not Found</h3>
          <p>Please enter your M-PESA transaction code to complete the process</p>
          <form onSubmit={handleTransactionCodeSubmit}>
            <div className="depositprocess-form-group">
              <label>Transaction Code</label>
              <input
                type="text"
                value={transactionCode}
                onChange={(e) => setTransactionCode(e.target.value)}
                className="depositprocess-input"
                placeholder="Enter M-PESA transaction code"
                required
              />
            </div>
            <button 
              type="submit" 
              className="depositprocess-verify-btn"
              disabled={isSubmittingPayment}
            >
              {isSubmittingPayment ? 'Verifying...' : 'Verify Payment'}
            </button>
          </form>
        </div>
      )}
    </div>
  );

  // Update the renderPaymentSuccessView function
  const renderPaymentSuccessView = () => (
    <div className="depositprocess-success">
      <div className="depositprocess-success-card">
        <FaCheckCircle className="depositprocess-success-icon" />
        <h2>Payment Successful!</h2>
        <p>Your payment of KES {depositAmount} has been processed successfully.</p>
        <p>Your account has been credited with KES {depositAmount}.</p>
        {isActivated && (
          <p className="activation-success">Your account has been successfully Recharged!</p>
        )}
        <button 
          className="depositprocess-success-btn"
          onClick={async () => {
            await refreshAllData();
            setCurrentView('main');
            setDepositAmount('');
            setPaymentSuccess(false);
            setShowTransactionForm(false);
          }}
        >
          Back to Home
        </button>
      </div>
    </div>
  );



  const renderDepositView = () => (
    <div className="deposit-view">
      <div className="deposit-header">
        <button className="back-btn" onClick={() => setCurrentView('main')}>
          <FaArrowLeft /> Back
        </button>
        <h2>Deposit</h2>
      </div>
      <h3>Deposit Amount</h3>
      <div className="amount-chips">
        {[500, 1000, 5000, 12000, 25000, 100000].map((amount) => (
          <button
            key={amount}
            className={`amount-chip ${depositAmount === amount.toString() ? 'selected' : ''}`}
            onClick={() => setDepositAmount(amount.toString())}
          >
            {amount.toLocaleString()}
          </button>
        ))}
      </div>
      <input
        type="number"
        placeholder="Enter amount (Minimum: KES 500)"
        value={depositAmount}
        onChange={(e) => {
          const value = e.target.value;
          setDepositAmount(value);
          if (toastNotification) {
            setToastNotification(null);
          }
        }}
        className="deposit-input"
      />
      <h3>Payment Channel</h3>
      <div className="payment-channel">
        <img src="/ic_mpesa.png" alt="Mpesa" className="payment-logo" />
        <span>Mpesa</span>
        <FaCheckCircle className="check-icon" />
      </div>
      <button 
        className="deposit-btn" 
        onClick={() => {
          if (!depositAmount || parseFloat(depositAmount) < 500) {
            setToastNotification({ 
              message: 'Minimum deposit amount is KES 500', 
              type: 'error' 
            });
            return;
          }
          setCurrentView('paymentProcess');
        }}
        disabled={!depositAmount}
      >
        PROCEED TO PAY
      </button>
    </div>
  );


  const renderView = () => {
    switch (currentView) {
      case 'deposit':
        return renderDepositView();
      case 'paymentProcess':
        return renderPaymentProcessView();
      case 'paymentSuccess':
        return renderPaymentSuccessView();
      case 'cashout':
        return renderCashoutView();
      case 'task':
        return renderTaskView();
      case 'investmentSuccess':
        return renderInvestmentSuccessView();
      default:
        return renderMainView();
    }
  };

  return (
    <div className="home-container">
      {renderView()}
      {showPopup && (
        <div className="welcome-popup">
            <img src="/apple-logo.png" alt="Logo" className="popup-logo" />
            <h2>Welcome to Vanguard Apple Holdings</h2>
            <p>Invest in the future of technology with our curated Apple investment packages.</p>
            <ol>
              <li>After registering with us, log in to your account and head on to our home page.</li>
              <li>On the HOME page, view the products and the daily income you expect to get and decide on the item you are interested in.</li>
              <li>Get instant Rewards and Discounts once you join our TELEGRAM CHANNEL by clicking the Telegram link below.</li>
              <li>Get instant Earnings On Referrals.</li>
            </ol>
                        <button 
                className="popup-btn download-btn" 
                onClick={downloadApp}
              >
                Download App
              </button>
              <button 
                className="popup-btn telegram-btn" 
                onClick={joinTelegramChannel}
              >
                Join Our Telegram Channel
              </button>
              <button 
                className="popup-btn" 
                onClick={() => setShowPopup(false)}
              >
                Back
              </button>
          </div>
      )}
       <BottomNavigation />
      {toastNotification && (
        <HomeToastNotification
          message={toastNotification.message}
          type={toastNotification.type}
          onClose={() => setToastNotification(null)}
        />
      )}
    </div>
  );

};

export default Home;