import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AuthProvider, { useAuth } from '../security/security/AuthContext';
import { checkUserRole } from '../security/api/ApiClient';
import LandingPage from '../component/LandingPage';
import Login from '../userDashboard/auth/Login';
import Signup from '../userDashboard/auth/SignUp';
import Home from '../userDashboard/Home';
import Team from '../userDashboard/Team';
import Me from '../userDashboard/Me';
import Order from '../userDashboard/Order';
import Proof from '../userDashboard/Proof';
import VerifyEmail from '../userDashboard/auth/VerifyEmail ';
import { EmailVerificationFailed } from '../userDashboard/auth/EmailVerified';
import { EmailVerified } from '../userDashboard/auth/EmailVerified';
import ForgotPassword from '../userDashboard/auth/ForgotPassword';
import ResetPassword from '../userDashboard/auth/ResetPassword';
import Loading from '../userDashboard/auth/loaders/Loading';

import Admin from '../admin/Admin';
import AdminLogin from '../admin/AdminLogin';


function AdminAuthenticatedRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [checkingRole, setCheckingRole] = React.useState(true);

  React.useEffect(() => {
    const verifyAdminRole = async () => {
      if (isAuthenticated) {
        try {
          const role = await checkUserRole();
          setIsAdmin(role === 'ADMIN');
        } catch (error) {
          console.error('Error verifying admin role:', error);
          setIsAdmin(false);
        }
      }
      setCheckingRole(false);
    };

    verifyAdminRole();
  }, [isAuthenticated]);

  if (isLoading || checkingRole) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" />;
  }

  if (!isAdmin) {
    return <Navigate to="/home" replace />;
  }

  return children;
}

function AuthenticatedRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth();
  
  if (isLoading) {
    return <Loading />;
  }
  
  if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
}

export default function AppRoutes() {


  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path='admin/login' element={ <AdminLogin /> } />
            <Route path="/signup" element={<Signup />} />
            <Route path='/forgot-password' element={ <ForgotPassword /> } />
            <Route path='/reset-password' element={<ResetPassword /> } />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path='/verified' element={ <EmailVerified /> } />
            <Route path='/verification-failed' element={ <EmailVerificationFailed /> } />
            <Route path="/home" element={<AuthenticatedRoute><Home /></AuthenticatedRoute>} />
            <Route path="/team" element={<AuthenticatedRoute><Team /></AuthenticatedRoute>} />
            <Route path="/me" element={<AuthenticatedRoute><Me /></AuthenticatedRoute>} />
            <Route path="/order" element={<AuthenticatedRoute><Order /></AuthenticatedRoute>} />
            <Route path="/proof" element={<AuthenticatedRoute><Proof /></AuthenticatedRoute>} />

            {/* Admin route with special protection */}
            <Route 
              path="/admin/dashboard" 
              element={
                <AdminAuthenticatedRoute>
                  <Admin />
                </AdminAuthenticatedRoute>
              } 
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}